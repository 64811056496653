<template>
  <div class="home">
    <div class="background" :style="{ backgroundColor: bgColor }"></div>
    <div class="home-container">
      <div class="gems-board">
        <div class="board-container">
          <div class="label-text">Total balance</div>
          <div class="gems-number">
            <AnimationNumber
              :numm="formatNumberUsingCash(limitDecimals(balance, 2))"
            />
          </div>
          <div class="label-text">Gems</div>
        </div>
        <div class="board-container">
          <div class="label-text">Gems rewards</div>
          <div class="gems-number">
            <AnimationNumber
              :numm="formatNumberUsingCash(limitDecimals(rewards, 2))"
            />
          </div>
          <div class="label-text">Gems</div>
        </div>
      </div>
      <div class="tab-container">
        <div class="top-part">
          <div class="tab-text-area">
            <div class="content">
              <div class="title">DAILY CHECK-IN</div>
              <div class="subtitle">
                You've checked in for {{ consecutiveDays }} day! 🎉
              </div>
            </div>
          </div>
          <div class="no-text-area">
            <div class="tab-corner"></div>
            <div class="wiser-container">
              <div class="wiser"></div>
            </div>
          </div>
        </div>
        <div class="bottom-part">
          <CardSlider
            @on-consecutive-days-changed="(days) => (consecutiveDays = days)"
          />
          <div class="description">
            <div class="desc-title">
              <div style="margin-right: 4px">
                <img src="@/assets/star-small.png" style="margin-top: 8px" />
                <img src="@/assets/star-big.png" style="margin-bottom: 4px" />
              </div>
              Gems: Earn & Use
              <div style="margin-left: 4px">
                <img src="@/assets/star-big.png" style="margin-top: 4px" />
                <img src="@/assets/star-small.png" style="margin-bottom: 8px" />
              </div>
            </div>
            <div class="detail-title" :class="{ 'opacity-1': true }">
              Earn Daily:
            </div>
            <div
              class="detail-info"
              v-if="!extend"
              :class="{ 'opacity-1': !extend }"
            >
              Check in daily to earn up to ₹7. Hit a 7-day streak for a ₹7
              bonus! ...

              <span class="detail-button" @click="() => (extend = true)">
                Check more
              </span>
            </div>

            <div
              class="detail-info"
              v-if="extend"
              :class="{ 'opacity-1': extend }"
            >
              Check in daily to earn up to ₹7. Hit a 7-day streak for a ₹7
              bonus!
            </div>
            <div
              class="detail-title"
              style="margin-top: 20px"
              v-if="extend"
              :class="{ 'opacity-1': extend }"
            >
              Spend Your Gems:
            </div>
            <div
              class="detail-info"
              v-if="extend"
              :class="{ 'opacity-1': extend }"
            >
              Exchange Gems for exclusive astrological consultations. Start with
              as little as ₹100 for a deep dive into the stars!
              <span class="detail-button" @click="() => (extend = false)">
                Fold
              </span>
            </div>
            <div class="confirming-button" v-if="!isTodayCheckin">
              <div class="background-container" @click="checkin">
                <div class="top-background">Check in</div>
                <div class="bottom-background"></div>
              </div>
            </div>
            <div v-else class="see-you-tomorrow">See you tomorrow</div>
          </div>
        </div>
      </div>
      <div class="task-list">
        <div class="title">Task List</div>
        <div class="task-cell-container">
          <div class="task-cell" :key="index" v-for="(item, index) in taskList">
            <div class="banner" @click="toDetailLink(item.name)">
              <img :src="item.image" />
            </div>
            <div class="desc-container">
              <div
                class="complete-task"
                v-if="item.done && item.is_claim"
              ></div>
              <div class="title-area">
                <div class="icon"><img :src="item.icon" /></div>
                <div class="name">{{ item.name }}</div>
              </div>
              <div class="content">{{ item.content }}</div>
              <div class="button-area">
                <button class="get-gem-button">
                  <div class="icon"></div>
                  <div class="text">+{{ item.gems }}Gems</div>
                </button>
              </div>
              <div
                class="claim-confirming-button"
                v-if="item.done && !item.is_claim"
              >
                <div class="background-container" @click="claim(item)">
                  <div class="top-background">Claim gems</div>
                  <div class="bottom-background"></div>
                </div>
              </div>
              <div v-if="item.done && item.is_claim" class="task-complete">
                Task completed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./index.scss";
@import "@/styles/confirmbutton.scss";
</style>
<script setup>
import { watch, watchEffect, ref } from "vue";
import CardSlider from "../../components/CardSlider/CardSlider.vue";
import { gemsTokenContract, rewardsContractAddress } from "@/constants";
import { useWallet } from "@/dappos/hooks/useWallet";
import AnimationNumber from "@/dappos/components/animationNumber";
import { limitDecimals, formatNumberUsingCash } from "@/utils";
import { useHomeBgcolor } from "@/hooks/useHomeBgColor";
import erc20ABI from "@/contracts/abis/erc-20.json";
import rewardsABI from "@/contracts/abis/UserActive.json";
import { ethers } from "ethers";
import { getProvider } from "@/composables/use-provider";
import TriggerEvent from "@/dappos/utils/triggerEvent";
import { useRouter } from "vue-router";
import { useActionCaller } from "@/hooks/useActionCaller";
import { useDataLoader } from "@/hooks/useDataLoader";
import { useNeedConnectWalletDialog } from "@/dappos/hooks/useNeedConnectWalletDialog";
const { isConnected } = useNeedConnectWalletDialog();

const router = useRouter();

const extend = ref(false);

const { bgColor } = useHomeBgcolor();

const balance = ref(0);
const rewards = ref(0);

const { eoaAddress, vwAddress } = useWallet();

const consecutiveDays = ref(0);

const { callClaimRewards } = useActionCaller();

const { taskList, loadTaskList, isTodayCheckin, getIsTodayCheckedIn } =
  useDataLoader();

const resetBalance = () => {
  balance.value = 0;
  rewards.value = 0;
};

const toDetailLink = (name) => {
  router.push({ path: "/details", query: { platform: name.toLowerCase() } });
};

const loadGemsBalance = async (vw) => {
  if (!vw) return;
  const balanceContract = new ethers.Contract(
    gemsTokenContract,
    erc20ABI,
    getProvider(137)
  );

  const rewardsContract = new ethers.Contract(
    rewardsContractAddress,
    rewardsABI,
    getProvider(137)
  );
  const [b, r] = await Promise.all([
    balanceContract["balanceOf"].apply(null, [vw]),
    rewardsContract["totalMintted"].apply(null, [vw]),
  ]);
  console.log("gems balance", b);
  balance.value = ethers.utils.formatUnits(b, 18);
  rewards.value = ethers.utils.formatUnits(r, 18);
};

const checkin = () => {
  TriggerEvent.emit("checkinCommit");
};

const claim = (item) => {
  const gems = item.gems;
  const task = item.name.toLowerCase();
  callClaimRewards(gems, task);
};

const load = () => {
  loadTaskList(eoaAddress.value, vwAddress.value);
  loadGemsBalance(vwAddress.value);
  getIsTodayCheckedIn(eoaAddress.value, vwAddress.value);
};

TriggerEvent.on("loadHomeInfo", () => {
  load();
});

watchEffect(() => {
  loadTaskList(eoaAddress.value, vwAddress.value);
  getIsTodayCheckedIn(eoaAddress.value, vwAddress.value);
});

watchEffect(() => {
  loadGemsBalance(vwAddress.value);
});

watch(isConnected, () => {
  resetBalance();
});
</script>
