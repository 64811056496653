import { watch, computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useEthereumStore } from "@/stores/ethereum.store";
import { getProvider } from "@/composables/use-provider";
import { DappOSWalletLite } from "@dappos/wallet-lite";
import { DappOSProtocol } from "@dappos/checkout";
import { shallowRef } from "vue";
import { IConnector, useConnector } from "@/composables/use-connector";
import { appName, AddressZero, supportChains } from "@/constants";
import { useAsyncState, watchOnce } from "@vueuse/core";
import { getVirtualWallet } from "@/dappos/utils/getVirtualWallet";
import TriggerEvent from "@/dappos/utils/triggerEvent";
import { useClaimSuccessDialog } from "@/dappos/hooks/useClaimSuccessDialog";
import axios from "axios";

const DappOSProtocolStore = defineStore("DappOSProtocolStore", () => {
  const dappOSWalletLite = new DappOSWalletLite();
  const _dappOSProtocol = shallowRef(new DappOSProtocol());

  const _dappOSpProtocolInitPromise = shallowRef();

  const _paydbAddress = ref(AddressZero);
  const _isInitialConnection = ref(true);

  const { ethereumState, ethereumStore } = useEthereumStore();
  const { activeConnector } = useConnector();
  const executeThrottle = async () => {
    const res = await connectDappOSProtocol();
    return res;
  };

  const { open: openClaimSuccessDialog } = useClaimSuccessDialog();

  const vwAccount = ref();

  const initialConnectionState = useAsyncState(
    async () => {
      const fn = async () => {
        console.log("init start");

        const dappOSProtocolOption = {
          metadata: {
            name: appName,
            url: window.location.origin,
            icon: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/guruji.png",
          },
          env: process.env.VUE_APP_DAPPOS_ENV,
          rpcMaps: {},
        };
        await _dappOSProtocol.value.init(dappOSProtocolOption).finally(() => {
          _dappOSpProtocolInitPromise.value = undefined;
          console.log("init end");
          _isInitialConnection.value = false;
        });
      };

      if (_isInitialConnection.value && !_dappOSpProtocolInitPromise.value) {
        _dappOSpProtocolInitPromise.value = fn();
        await _dappOSpProtocolInitPromise.value;
      }

      return _dappOSProtocol.value;
    },
    _dappOSProtocol.value,
    {
      immediate: true,
    }
  );
  const _dappOSProtocolConnecting = useAsyncState(
    async () => {
      console.time("connect end");
      await executeThrottle()
        .then(async (e) => {
          if (!e) return;
          console.log(e);
          console.log(
            "%cdappOSProtocol connect success",
            "color:#2aae68; font-size: 16px; font-weight: bold;"
          );
        })
        .catch(console.error);
      return _dappOSProtocol.value;
    },
    _dappOSProtocol.value,
    {
      immediate: true,
    }
  );

  async function connectDappOSProtocol() {
    console.log(
      "%cdappOSProtocol connect: ",
      "color:#2aae68; font-size: 16px; font-weight: bold;"
    );
    if (
      ethereumState.account.value &&
      ethereumState.network.value &&
      supportChains.includes(Number(ethereumState.network.value?.chainId))
    ) {
      vwAccount.value = null;
      loadVwInfo();
      const connector = (() => {
        const metamask = {
          name: "MetaMask",
          logo: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/metamask.png",
        };
        switch (activeConnector.value) {
          case IConnector.METAMASK:
            return metamask;
          case IConnector.WALLET_CONNECT:
            return {
              name: "WalletConnect",
              logo: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/walletconnect.png",
            };
          case IConnector.BITGET:
            return {
              name: "Bitget",
              logo: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/bitget.svg",
            };
          case IConnector.GOOGLE:
            return {
              name: "google",
              logo: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/cubi.png",
            };
          case IConnector.TWITTER:
            return {
              name: "twitter",
              logo: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/cubi.png",
            };
          case IConnector.FACEBOOK:
            return {
              name: "facebook",
              logo: "https://dappos-public-resource.s3.amazonaws.com/dappLogo/cubi.png",
            };
          default:
            return metamask;
        }
      })();

      if (!initialConnectionState.isReady.value) {
        console.log("await init promise");
        // await _dappOSpProtocolInitPromise.value;

        await Promise.race([
          new Promise((resolve) => {
            watchOnce(initialConnectionState.isReady, (e) => {
              console.log("isReady", e);
              resolve(1);
            });
          }),
        ]).then((e) => {
          console.log("init end race", e);
        });
      }
      console.table(
        JSON.parse(
          JSON.stringify({
            account: ethereumState.account.value,
            network: ethereumState.network.value?.chainName,
            connector: activeConnector.value,
          })
        )
      );

      const { version } = await dappOSWalletLite.connect(
        ethereumState.account.value,
        [137],
        [137]
      );
      console.log("current version ~~~", version);
      await _dappOSProtocol.value.connect({
        version, // wallet-lite
        connector,
        chainId: ethereumState.network.value
          ? Number(ethereumState.network.value?.chainId)
          : 56,
        owner: ethereumState.account.value,
        provider: getProvider(),
        ownerSigner: getProvider().getSigner(),
      });

      console.log("ownerSigner: ", getProvider().getSigner());

      if (ethereumState.network.value) {
        _paydbAddress.value = await _dappOSProtocol.value.getPayDbAddress(
          Number(ethereumState.network.value?.chainId)
        );
      }
      return true;
    }
    return false;
  }

  const { isReady: dappOSProtocolIsReady, isLoading: dappOSProtocolIsLoading } =
    _dappOSProtocolConnecting;

  const getDappOSProtocol = async () => {
    return _dappOSProtocol.value;
  };

  const reset = () => {
    //TODO: reset
  };

  ethereumStore.event.on("connectChanged", async () => {
    console.log("connectChanged .....");
    await _dappOSProtocolConnecting.execute(0);
  });

  ethereumStore.event.on("protocolVersionChanged", async () => {
    console.log("trigger protocolVersionChanged");
    location.reload();
  });

  const loadVwInfo = async () => {
    vwAccount.value = await getVirtualWallet(ethereumState.account.value, 137, {
      cache: false,
    });
  };

  TriggerEvent.on("loadVwInfo", () => {
    loadVwInfo();
  });

  setInterval(() => {
    console.log("timer....");
    if (
      dappOSProtocolIsReady &&
      vwAccount.value &&
      vwAccount.value?.walletCreated
    ) {
      console.log("vw is ready");
    } else {
      console.log("continue");
      loadVwInfo();
    }
  }, 5000);

  const callClaimDappOSBonusAPI = async () => {
    const eoa = ethereumState.account.value;
    const url = "https://proxy.dappos.com/teller/reward_token/transfer";
    const res = await axios.get(url, {
      params: { eoaAddress: eoa },
    });
    const hash = res.data;
    console.log("gems claim hash == ", hash);
    if (hash && typeof hash === "string") {
      openClaimSuccessDialog(10); // fixed 10 gems
    }
  };

  watch([vwAccount], () => {
    if (vwAccount.value && vwAccount.value?.walletCreated) {
      callClaimDappOSBonusAPI();
    }
  });

  return {
    isLoading: dappOSProtocolIsLoading,
    reset,
    getDappOSProtocol,
    vwAccount,
    dappOSProtocol: _dappOSProtocol,
    dappOSProtocolIsReady,
    paydbAddress: computed(() => _paydbAddress.value),
  };
});

export const useDappOSProtocolStore = () => {
  const store = DappOSProtocolStore();
  const state = storeToRefs(store);

  return {
    ...state,
    isProtocolReady: state.dappOSProtocolIsReady,
    vwAccount: state.vwAccount,
    getDappOSProtocol: store.getDappOSProtocol,
    readyProtocol: store.getDappOSProtocol,
    reset: store.reset,
  };
};
