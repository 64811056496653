import { getEvmChainParameter } from "@/constants";
import { ProviderNotFoundError } from "@/modules/connector";
import { CubistProvider } from "cubist-provider";
import { useStorageStore } from "@/stores/storage.store";

export class OIDCConnectConnector {
  provider;
  connectOpt;
  #onDisconnectHandler;
  #onAccountsChangedHandler;
  #onChainChangedHandler;

  constructor(opt) {
    this.connectOpt = opt;
    this.init(true);
  }

  async init(reset) {
    if (reset) {
      this.provider = undefined;
    }
    if (!this.provider) {
      this.provider = CubistProvider.init({
        name: "Conntect By Cubist",
        chain: 137,
        optionalChains: [56, 10, 137, 43114, 42161, 1, 169],
        rpcMap: {
          56: "https://rpc.ankr.com/bsc",
          10: "https://rpc.ankr.com/optimism",
          137: "https://rpc.ankr.com/polygon",
          43114: "https://rpc.ankr.com/avax",
          42161: "https://rpc.ankr.com/arbitrum",
          169: "https://manta-pacific.drpc.org",
          1: "https://rpc.ankr.com/eth",
        },
      });
      return this.provider;
    } else {
      return this.provider;
    }
  }

  async connect() {
    const { storage } = useStorageStore();

    if (!this.provider) {
      throw new ProviderNotFoundError();
    }
    const isConnected = await this.provider?.isConnected();
    if (!isConnected) {
      const cubistLoginType = storage.cubistLoginType.value;
      await this.provider?.connect(cubistLoginType);
    }
    const result = this.provider.isNewToCubist();

    const localNewer = `${storage.cubistNewer.value}`;
    if (localNewer === "false" || localNewer === "null") {
      storage.cubistNewer.value = result;
    }

    const [accounts, network] = await Promise.all([
      await this.provider?.getAddress(),
      Promise.race([
        this.provider?.send("eth_chainId", []).then((e) => {
          return Number(e);
        }),
        this.provider?.getNetwork().then((e) => e.chainId),
      ]).then((e) => {
        return Number(e);
      }),
    ]);

    return {
      accounts,
      provider: this.provider,
      network,
    };
  }

  async disconnect() {
    return await this.provider?.disconnect();
  }

  // auto connect
  async isConnected() {
    if (!this.connectOpt?.autoConnect) return false;
    if (!this.provider) return false;
    await this.provider.autoConnect();

    return this.provider?.isConnected();
  }

  // TODO: add event switch chain, acocunt change

  async switchChain(chainId) {
    if (!this.provider) throw new ProviderNotFoundError();
    const evmParameter = getEvmChainParameter(chainId);
    console.log(evmParameter);
    try {
      await this.provider.send("wallet_switchEthereumChain", [
        { chainId: evmParameter.chainId },
      ]);
    } catch (err) {
      if (err.code === 4902) {
        try {
          const evmParameter = getEvmChainParameter(chainId);
          await this.addChain(evmParameter);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  async addChain(networkDetails) {
    if (!this.provider) throw new ProviderNotFoundError();
    try {
      this.provider.send("wallet_addEthereumChain", [networkDetails]);
    } catch (err) {
      throw Error("wallet_addEthereumChain \n");
    }
  }

  onDisconnect(handler) {
    if (!this.provider) throw new ProviderNotFoundError();
    if (this.#onDisconnectHandler) {
      this.provider.removeListener("disconnect", this.#onDisconnectHandler);
      this.provider.removeListener("session_delete", this.#onDisconnectHandler);
    }
    this.#onDisconnectHandler = handler;
    this.provider.on("disconnect", () => {
      handler();
    });
    this.provider.on("session_delete", () => {
      handler();
    });
  }

  onAccountsChanged(handler) {
    if (!this.provider) throw new ProviderNotFoundError();
    if (this.#onDisconnectHandler) {
      this.provider.removeListener(
        "accountsChanged",
        this.#onDisconnectHandler
      );
    }
    this.#onAccountsChangedHandler = handler;
    this.provider.on("accountsChanged", (accounts) => {
      handler(accounts);
    });
  }

  onChainChanged(handler) {
    if (!this.provider) throw new ProviderNotFoundError();
    if (this.#onChainChangedHandler) {
      this.provider.removeListener("chainChanged", this.#onChainChangedHandler);
    }
    this.#onChainChangedHandler = handler;
    this.provider.on("chainChanged", (chainId) => {
      handler(chainId);
    });
  }
}
