export * from "./networks";
export const AddressZero = "0x0000000000000000000000000000000000000000";
export const supportChains = [1, 10, 56, 137, 43114, 169, 42161];

export const gemsTokenContract = "0x73B56430D7bF81F7004d265097A41aA639c2a233";
export const rewardsContractAddress =
  "0xa6a240d8AEf5e98E2e19880aB39b2EedE4A7c666";
export const serviceAddressMap = {
  137: "0x6C1Ff58F9fb28220f589D6c515CBB920A2D1c422",
};
export const appName = "Guruji";

export const gurujiDomain =
  process.env.NODE_ENV === "development"
    ? "/api"
    : process.env.VUE_APP_GURUJI_API;
