import { ref } from "vue";

const isOpen = ref(false);

const gems = ref(0);
const totalGems = ref(0);
const tomorrowGems = ref(0);
const checkdate = ref("Today");
const index = ref(0);
const isSpecial = ref(false);

export function useSignInSuccessDialog() {
  const open = ({
    gems: g,
    totalGems: tg,
    tomorrowGems: tog,
    checkdate: c,
    index: i,
    isSpecial: s,
  }) => {
    if (g) gems.value = g;
    if (tg) totalGems.value = tg;
    if (tog) tomorrowGems.value = tog;
    if (c) checkdate.value = c;
    if (i) index.value = i;
    if (s) isSpecial.value = s;

    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  return {
    gems,
    totalGems,
    tomorrowGems,
    checkdate,
    index,
    isSpecial,
    isOpen,
    open,
    close,
  };
}
