import { defineStore } from "pinia";
import { ref } from "vue";
import { useProvider } from "@/composables/use-provider";
import { getEvmChainParameter } from "@/constants";

export const useConnectedNetwork = defineStore("useConnectedNetwork", () => {
  const { provider } = useProvider();

  const network = ref();
  const setNetwork = (chainId) => {
    const evmParameter = getEvmChainParameter(chainId);
    const _chainId = `0x${chainId.toString(16)}`;
    if (evmParameter) {
      network.value = evmParameter;
    } else {
      const defaultInfo = {
        chainId: _chainId, // A 0x-prefixed hexadecimal string
        chainName: "",
        nativeCurrency: {
          name: "",
          symbol: "", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: [],
        blockExplorerUrls: [],
        iconUrls: [], // Currently ignored.
      };
      try {
        if (provider.value?.network?.name) {
          defaultInfo.chainName = provider.value?.network?.name;
        }
      } catch (error) {
        console.log(error);
      }
      network.value = defaultInfo;
    }
  };

  const updateNetwork = () => {
    if (!provider.value) return false;
    return provider.value.getNetwork().then(({ chainId }) => {
      setNetwork(chainId);
    });
  };

  return { network, updateNetwork, setNetwork };
});
