// import { getEvmChainParameter } from '~/constants'
import { h, createVNode } from "vue";
import { Notification } from "@arco-design/web-vue";
import SuccessSvg from "@/assets/img/svg/success.svg";
import ErrorSvg from "@/assets/img/svg/error.svg";

function hashFilter(val) {
  return val.substr(0, 6) + "..." + val.substr(-3);
}
// export function hashNotify(chain, hash, type = 'success', failCause) {
//   const { chainName, blockExplorerUrls } = getEvmChainParameter(chain)
//   const hashMsg = `<a
//   href="${blockExplorerUrls[0]}/tx/${hash}"
//   target="_blank"
//   >
//     <span>View on ${chainName}:</span>
//     <span>${hashFilter(hash)}</span>
//   </a>`

//   return ElNotification({
//     type: type,
//     title: `Transaction ${failCause || type}`,
//     customClass: 'dappos_notify',
//     duration: 1000 * 10.33,
//     dangerouslyUseHTMLString: true,
//     message: hashMsg
//   })
// }

export function orderHashNotify(hash, type = "success", failCause) {
  const hashMsg = createVNode(
    "a",
    {
      href: `${process.env.VUE_APP_DAPPOS_EXPLORER_HOST}/activities/details?order_hash=${hash}`,
      target: "_blank",
    },
    [
      createVNode("span", null, "View on Explorer:"),
      createVNode("span", null, hashFilter(hash)),
    ]
  );

  if (type !== "success") {
    return Notification.error({
      title: `Transaction ${failCause || type}`,
      content: hashMsg,
      duration: 1000 * 10.33,
      closable: true,
      showIcon: true,
      icon: h("img", { src: ErrorSvg }),
      class: "dappos_notify",
    });
  } else {
    return Notification.success({
      title: `Transaction ${failCause || type}`,
      content: hashMsg,
      duration: 1000 * 10.33,
      closable: true,
      showIcon: true,
      icon: h("img", { src: SuccessSvg }),
      class: "dappos_notify",
    });
  }
}

export function orderTextNotify(type = "success") {
  const hashMsg = createVNode(
    "a",
    {
      href: `${process.env.VUE_APP_DAPPOS_EXPLORER_HOST}/dashboard`,
      target: "_blank",
    },
    [createVNode("span", null, `Waiting for signer's confirmation`)]
  );

  if (type !== "success") {
    return Notification.error({
      title: `Transaction submitted`,
      content: hashMsg,
      duration: 1000 * 10.33,
      closable: true,
      showIcon: true,
      icon: h("img", { src: ErrorSvg }),
      class: "dappos_notify",
    });
  } else {
    return Notification.success({
      title: `Transaction submitted`,
      content: hashMsg,
      duration: 1000 * 10.33,
      closable: true,
      showIcon: true,
      icon: h("img", { src: SuccessSvg }),
      class: "dappos_notify",
    });
  }
}
