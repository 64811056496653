<template>
  <div class="number-digital">
    <div class="relative flex">
      <div
        v-for="(item, index) in orderNum"
        :key="index"
        :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
      >
        <div v-if="!isNaN(item)" class="box-num">
          <span
            :id="`${token}-numberItem${index}`"
            :class="{ 'top-2': !isPC() }"
            class="font-Roboto-700 text-48 leading-48 text-gs-900"
            >0123456789</span
          >
        </div>
        <div
          v-if="[',', '.', 'k', 'm', 'b', 't'].includes(item)"
          class="num-dot font-Roboto-700 text-48 leading-48 text-gs-900"
          :style="['k', 'm', 'b', 't'].includes(item) ? { left: 0 } : {}"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, toRefs, defineProps } from "vue";
import { isPC } from "@/utils";
const props = defineProps(["numm"]);
const { numm } = toRefs(props);
const token = (Math.random() * 1000000).toFixed();

const orderNum = ref([]);
const toOrderNum = (num) => {
  orderNum.value = num.split("");
};
watch(
  () => numm.value,
  (newVal) => {
    toOrderNum(newVal);
    setTimeout(() => {
      setNumberTransform();
    }, 200);
  }
);
function setNumberTransform() {
  for (let index = 0; index < orderNum.value.length; index++) {
    const ele = document.getElementById(`${token}-numberItem${index}`);
    if (ele) {
      ele.style.transform = `translate(-50%, -${orderNum.value[index] * 10}%)`;
    }
  }
}
onMounted(() => {
  toOrderNum(numm.value);
  setTimeout(() => {
    setNumberTransform();
  }, 200);
});
</script>
<style lang="scss" scoped>
.number-digital {
  margin-right: 8px;
  .relative {
    position: relative;
  }

  .flex {
    display: flex;
  }
  .leading-48 {
    font-size: 35px;
  }
  .text-48 {
    line-height: 35px;
  }
  .top-2 {
    top: -2px;
  }

  .number-item {
    width: 22px;
    height: 35px;

    .box-num {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      writing-mode: vertical-lr;
      text-orientation: upright;
      overflow: hidden;
      cursor: default;
      & > span {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        transition: transform 1s ease-in-out;
        letter-spacing: 10px;
        // text-align: center;
      }
    }
  }

  .mark-item {
    position: relative;
    margin-right: 5px;
  }

  .num-dot {
    position: absolute;
    bottom: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
  }

  .number-item:last-child {
    margin-right: 0;
  }
}
</style>
