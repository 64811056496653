import axios from "axios";
import { ERROR_CODE } from "@/dappos/utils/error-code";
import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { calculateOrderProgress } from "@/dappos/utils/order-progress";
import { orderHashNotify, orderTextNotify } from "@/dappos/utils/msg-notify";
import { useWallet } from "@/dappos/hooks/useWallet";
import { useDappOSProtocolStore } from "@/stores/dappos-protocol.store";
import { getProvider } from "@/composables/use-provider";
import TriggerEvent from "@/dappos/utils/triggerEvent";

const BASE_URL = process.env.VUE_APP_DAPPOS_RPC_URL;

export const TransactionsStore = defineStore("TransactionsStore", () => {
  const { dstChainId } = useWallet();
  const { readyProtocol } = useDappOSProtocolStore();
  const transactionState = ref({
    isPolling: false,
    process: 0,
    pendingCount: 0,
    transactions: [],
    hashSet: {},
  });

  const setTransactionProcess = (val) => {
    transactionState.value.process = val;
  };

  const transactionStart = () => {
    transactionState.value.isPolling = true;
    transactionState.value.process = 0;
  };
  const transactionFinish = () => {
    transactionState.value.process = 100;
    transactionState.value.isPolling = false;
  };
  const pushTransaction = (hash, options) => {
    if (!transactionState.value.hashSet[hash]) {
      transactionState.value.pendingCount += 1;
      transactionState.value.hashSet[hash] = true;
      transactionState.value.transactions.push({
        hash,
        options,
      });
    }
  };

  const shiftTransaction = () => {
    const transactions = transactionState.value.transactions;
    const hash = transactions.shift()?.hash;
    if (hash) {
      delete transactionState.value.hashSet[hash];
    }
    transactionState.value.pendingCount = transactions.length;
    if (transactions.length) {
      const { hash, options } = transactions[0];
      // transaction 需要一个一个查看执行
      pollOrderHash(hash, options);
    }
  };

  const getOrderInfo = async (orderHash) => {
    const url = `${BASE_URL}/supernode/v2/orders/action/get_order_by_hash`;
    const params = { order_hash: orderHash };
    return axios.get(url, { params }).then((res) => {
      return res.data;
    });
  };

  /**
   * loop function
   * @param orderHash - order hash
   * @param interval - millisecond
   * @param maxAttempts - max try times
   * @returns undefined
   */
  const pollOrderHash = async (orderHash, options) => {
    return new Promise((resolve, reject) => {
      // for institution signer
      if (typeof orderHash === "boolean" && orderHash) {
        orderTextNotify("success");
        resolve({
          state: 3,
          data: { orderHash: true, txs: [], sender: "" },
          isSuccess: true,
          isEnd: true,
          isFailure: false,
          progress: 100,
          failureReason: "",
        });
        return;
      }
      pushTransaction(orderHash, options);
      if (transactionState.value.isPolling) return;
      const interval = options?.interval || 400;
      const maxAttempts = options?.maxAttempts || 999;
      transactionStart();
      let attempts = 0;
      let timer;
      const poll = async () => {
        timer ? clearTimeout(timer) : null;
        const result = await getOrderInfo(orderHash);
        const pollOrderRes = pollResDeal(result);
        setTransactionProcess(pollOrderRes.progress);
        if (pollOrderRes.isEnd) {
          console.log("looping searching result：", pollOrderRes.isSuccess);
          if (pollOrderRes.isSuccess) {
            const condition = result.txs.some(
              (tx) => !!tx.vw && tx.vw.chainId === dstChainId.value
            );
            if (condition) {
              const txArr = result.txs.filter(
                (tx) => !!tx.vw && tx.vw.chainId === dstChainId.value
              );
              console.log("getProvider", getProvider(dstChainId.value));
              console.log(
                "[transaction success] this tx need to be tested",
                txArr[txArr.length - 1].vw.hash
              );
              await getProvider(dstChainId.value).waitForTransaction(
                txArr[txArr.length - 1].vw.hash,
                1
              );
            }
            console.log("reload assets");

            await (await readyProtocol()).updateAllAccountAsset();
            TriggerEvent.emit("loadVwInfo");
          }
          orderHashNotify(
            orderHash,
            pollOrderRes.isSuccess ? "success" : "error",
            pollOrderRes.failureReason
          );
          transactionFinish();
          resolve(pollOrderRes);
          shiftTransaction();
        } else if (attempts < maxAttempts) {
          attempts++;
          timer = setTimeout(poll, interval);
        } else {
          reject("Polling timed out");
        }
      };

      poll();
    });
  };

  const getOrderProgress = (txs = []) => {
    const stateMap = txs
      ?.flatMap((e) => [
        e.vw?.state,
        ...(e.createPays ?? []).map((p) => p?.state),
        ...(e.executePays ?? []).map((p) => p?.state),
      ])
      .filter((e) => e != undefined);
    const isSuccess = stateMap?.every((e) => e === 3);
    const failureState = stateMap?.find((e) => [4, 5].includes(e));
    if (isSuccess) {
      return {
        state: 3,
        isSuccess,
        isFailure: false,
        progress: 100,
      };
    }
    if (failureState) {
      const failureCodeList = txs
        .flatMap((e) => [
          e.vw?.failureReason,
          ...(e.createPays ?? []).map((p) => p?.failureReason),
          ...(e.executePays ?? []).map((p) => p?.failureReason),
        ])
        .filter((e) => e != undefined && e != "");
      const errorReason = failureCodeList.find((t) => !!ERROR_CODE[t]);
      const failureReason =
        errorReason || failureCodeList.find((t) => t) || "failed" || "";
      return {
        state: failureState,
        isSuccess: false,
        isFailure: !!failureState,
        progress: 100,
        failureReason,
      };
    }

    return {
      isSuccess: false,
      isFailure: false,
      progress: calculateOrderProgress(txs),
      state: 2,
    };
  };

  const pollResDeal = (data) => {
    const txs = data?.txs || [];
    const {
      progress,
      state,
      isSuccess,
      isFailure,
      failureReason = "",
    } = getOrderProgress(txs);
    console.log("current progress：", progress);

    return {
      data,
      state,
      progress,
      isSuccess,
      isFailure,
      isEnd: isSuccess || isFailure,
      failureReason,
    };
  };

  return {
    transactionState,
    pollOrderHash,
  };
});

export const useTransactionsStore = () => {
  const transactionsStore = TransactionsStore();
  const transactionsState = storeToRefs(transactionsStore);

  return {
    transactionsStore,
    transactionsState,
  };
};
