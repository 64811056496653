import { gurujiDomain } from "@/constants";
import axios from "axios";
import { ref } from "vue";
// import { useWallet } from "@/dappos/hooks/useWallet";

export const useDataLoader = () => {
  // const { eoaAddress, vwAddress } = useWallet();
  const taskList = ref([]);
  const isTodayCheckin = ref(false);
  const loadTaskList = async (eoa, vw) => {
    const url = `${gurujiDomain}/solar/dappos/get_task_list`;
    const res = await axios.get(url, {
      params: { eoaAddress: eoa ?? "", vwAddress: vw },
    });
    const list = res.data?.data?.task_list ?? [];
    if (!eoa) {
      taskList.value = list.map((i) => ({ ...i, done: false }));
    } else {
      taskList.value = await Promise.all(
        list.map(async (i) => {
          let done = false;
          if (i.name === "Dappos") {
            done = await loadCheckDappOSTask(eoa);
          } else {
            done = await loadCheckQuickSwapTask(eoa);
          }
          return {
            ...i,
            done,
          };
        })
      );
    }
  };

  const loadCheckQuickSwapTask = async (eoa) => {
    const url = "https://mantafest.dappos.com/dapposdata/vws/is_new_quick_user";
    const res = await axios.get(url, { params: { eoaAddress: eoa } });
    return !res.data;
  };

  const loadCheckDappOSTask = async (eoa) => {
    const url =
      "https://api.dappos.com/supernodeadmin/v2/wallet_owner/action/get_info";

    const res = await axios.get(url, {
      params: { owner_address: eoa, version: "2" },
    });

    return res.data?.infos?.length > 0 ?? false;
  };

  const getIsTodayCheckedIn = async (eoa, vw) => {
    const url = `${gurujiDomain}/solar/checkin/today_is_checkin`;
    const res = await axios.get(url, {
      params: { eoaAddress: eoa, vwAddress: vw },
    });
    const r = res.data?.data?.today_is_checkin ?? false;
    isTodayCheckin.value = r;
    return r;
  };

  return {
    isTodayCheckin,
    taskList,
    loadTaskList,
    getIsTodayCheckedIn,
  };
};
