import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home/HomeView.vue";
import { useConnector } from "@/composables/use-connector";
import { useEthereumStore } from "@/stores/ethereum.store";
import DetailView from "../views/Detail/DetailView.vue";

const routes = [
  {
    path: "/",
    name: "homeView",
    component: HomeView,
  },
  {
    path: "/details",
    name: "detailView",
    component: DetailView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

setTimeout(() => {
  const search = window.location.search;
  if (search.includes("connector")) {
    return;
  }
  const { ethereumState } = useEthereumStore();
  const { autoConnect } = useConnector();
  if (!ethereumState.accountConnected.value) {
    autoConnect();
  }
}, 300);

export default router;
