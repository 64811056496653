<template>
  <a-modal
    :visible="isDisplay"
    @cancel="closeDialog"
    modal-class="task-center-sign-in-success"
    :mask-closable="false"
    :render-to-body="true"
    :footer="false"
    width="343px"
    :title-align="'center'"
    unmount-on-close
  >
    <template #title> </template>
    <div class="content-container">
      <div class="card-container">
        <div
          class="hover-light card"
          :class="['card-' + index, 'showsUp']"
          style="bottom: 0"
        >
          <img :src="imageUrl" />
          <div
            class="bonus-container normal"
            :class="isSpecial ? 'special' : 'normal'"
          >
            <div class="top">+{{ gems }}Gems</div>
            <div class="bottom">
              {{ checkdate }}
            </div>
          </div>
        </div>
      </div>
      <div class="sign-in-success-container">
        <div class="title">
          <div style="margin-right: 4px">
            <img src="@/assets/star-small.png" style="margin-top: 8px" />
            <img src="@/assets/star-big.png" style="margin-bottom: 4px" />
          </div>
          Check-In Success!
          <div style="margin-left: 4px">
            <img src="@/assets/star-big.png" style="margin-top: 4px" />
            <img src="@/assets/star-small.png" style="margin-bottom: 8px" />
          </div>
        </div>
        <div class="context">
          You have Earned {{ totalGems }} Gems in total👏
        </div>
        <div class="context">
          Check in Tomorrow for +{{ tomorrowGems }} Gems!
        </div>
      </div>
      <div class="back-button-container">
        <div class="back-button" @click="closeDialog">Got it</div>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { computed } from "vue";
import { useSignInSuccessDialog } from "@/dappos/hooks/useSignInSuccessDialog";
import Shiva from "@/assets/god-shiva.png";
import Ganesha from "@/assets/god-ganesha.png";
import Hamnum from "@/assets/god-hamnum.png";
import Kali from "@/assets/god-kali.png";
import Lakshmi from "@/assets/god-lakshmi.png";

const {
  close,
  isOpen: isDisplay,
  gems,
  totalGems,
  tomorrowGems,
  checkdate,
  index,
  isSpecial,
} = useSignInSuccessDialog();

const getImageName = (index) => {
  switch (index) {
    case 0:
      return Shiva;
    case 1:
      return Ganesha;
    case 2:
      return Hamnum;
    case 3:
      return Kali;
    case 4:
      return Lakshmi;
    case 5:
      return Ganesha;
    case 6:
      return Hamnum;
    default:
      return "";
  }
};

function closeDialog() {
  close();
}

const imageUrl = computed(() => {
  return getImageName(index.value);
});
</script>
<style lang="scss" scoped>
@import "@/components/CardSlider/index.scss";
.task-center-sign-in-success-cell {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  span {
    margin-left: 10px;
    color: black;
    font-size: 14px;
  }
  &:hover {
    background-color: rgb(45 45 45 / 10%);
  }
}
.content-container {
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in-success-container {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    color: #000;
    font-style: italic;
    width: 232px;
    height: 32px;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .context {
    color: #000;
    font-size: 14px;
  }
}
.task-center-modal-icon {
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 50%;
  padding: 1px;
  background-color: white;
  width: 24px;
  height: 24px;
  .icon {
    border-radius: 50%;
    width: 22px;
  }
}
.back-button-container {
  margin-top: 35px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  .back-button {
    width: 163px;
    height: 46px;
    border-radius: 100em;
    font-size: 18px;
    font-weight: 500;
    background-color: #33005c;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.7;
  }
}
.card-container {
  position: relative;
  background: url("@/assets/astrolabium.png") no-repeat;
  background-size: 100% 100%;
  width: 225px;
  height: 225px;
}
.card-0 {
  background-color: rgb(241, 191, 149);
}
.card-1 {
  background-color: rgb(169, 208, 240);
}
.card-2 {
  background-color: rgb(207, 159, 219);
}
.card-3 {
  background-color: rgb(188, 207, 178);
}
.card-4 {
  background-color: rgb(235, 204, 220);
}
.card-5 {
  background-color: rgb(169, 208, 240);
}
.card-6 {
  background-color: rgb(207, 159, 219);
}
.hover-light {
  overflow: hidden;
}
.card.hover-light.showsUp {
  animation: showsUpAnimation 1.5s linear;
}
.hover-light::after {
  content: "";
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
  background-image: linear-gradient(
    to right,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
  -o-transform: skewx(-25deg);
  -moz-transform: skewx(-25deg);
  -webkit-transform: skewx(-25deg);
  transform: skewx(-25deg);
  animation: cardLightAnimation 1s linear infinite;
  animation-delay: 1s;
}

@keyframes cardLightAnimation {
  from {
    transition: all 0.5s ease;
    left: -100%;
  }
  to {
    transition: none;
    left: 300%;
  }
}

@keyframes showsUpAnimation {
  0% {
    opacity: 0;
    scale: 0;
  }
  25% {
    scale: 1.1;
    opacity: 1;
  }
  50% {
    scale: 0.9;
  }
  100% {
    scale: 1;
  }
}
</style>
