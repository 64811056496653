// FOR V2.0 SDK
import { nameConsole } from "./namespaceConsole";
import { useDappOSProtocolStore } from "@/stores/dappos-protocol.store";

export const usePackedSdk = () => {
  const { readyProtocol } = useDappOSProtocolStore();

  /**
   * @description all tranaction excpet withdraw to eoa
   * @param params
   * @param isSimulated
   * @param operationType it's console title
   * @returns
   */
  const sendTransaction = async (params, isSimulated, operationType) => {
    const protocol = await readyProtocol();
    console.log("[" + operationType + "] params", params);
    try {
      let res;
      if (isSimulated) {
        console.time("dappos simulate !!!!");
        res = await protocol.simulate.sendTransaction(params);
        console.timeEnd("dappos simulate !!!!");
      } else {
        res = await protocol.sendTransaction(params);
      }
      dynamicConsole(isSimulated, operationType, "result: ", res);
      return res;
    } catch (error) {
      dynamicConsole(isSimulated, operationType, "result: ", error, "error");
      throw error;
    }
  };

  const dynamicConsole = (
    isSimulated,
    operationType,
    desc,
    content,
    consoleType
  ) => {
    const prefix = isSimulated ? "Simulated - " : "";
    return nameConsole(prefix + operationType, desc, content, consoleType);
  };

  return {
    sendTransaction,
  };
};
