import { useEthereumStore } from "@/stores/ethereum.store";
import { useDappOSProtocolStore } from "@/stores/dappos-protocol.store";
import { computed } from "vue";
export const useWallet = () => {
  const { ethereumState } = useEthereumStore();
  const { isProtocolReady, vwAccount } = useDappOSProtocolStore();
  const srcChainId = computed(() =>
    Number(ethereumState.network.value?.chainId)
  );
  const eoaAddress = computed(() => ethereumState.account.value);
  const dstChainId = computed(() => ethereumState.dstChainId.value);
  const isIsolated = computed(
    () => Number(dstChainId.value) === Number(srcChainId.value)
  );
  const isReady = computed(() => isProtocolReady.value);
  const vwAddress = computed(() => vwAccount.value?.address);
  return {
    isReady,
    vwAddress,
    srcChainId,
    eoaAddress,
    dstChainId,
    isIsolated,
  };
};
