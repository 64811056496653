import { ref } from "vue";
const bgColor = ref("#2f0054");
export const useHomeBgcolor = () => {
  const setBgColor = (color) => {
    bgColor.value = color;
  };
  const resetColor = () => {
    bgColor.value = "#2f0054";
  };
  return {
    bgColor,
    setBgColor,
    resetColor,
  };
};
