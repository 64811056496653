import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useConnectedNetwork } from "@/stores/hooks/use-network";
import mitt from "mitt";
export const EthereumStore = defineStore("EthereumStore", () => {
  const account = ref();
  const dstChainId = ref(137); // polygon

  const connectNetwork = useConnectedNetwork();
  const { network } = storeToRefs(connectNetwork);

  const reset = () => {
    account.value = undefined;
  };

  const setDstChainId = (chainId) => {
    dstChainId.value = chainId;
  };

  const accountConnected = computed(() => {
    if (account.value && network.value?.chainId) {
      return `${account.value}-${Number(network.value?.chainId)}`;
    }
    return false;
  });

  return {
    accountConnected,
    network,
    account,
    dstChainId,
    reset,
    setDstChainId,
    event: mitt(),
  };
});

export const useEthereumStore = () => {
  const ethereumStore = EthereumStore();
  const ethereumState = storeToRefs(ethereumStore);

  return {
    ethereumStore,
    ethereumState,
  };
};
