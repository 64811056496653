<template>
  <a-modal
    :visible="isDisplay"
    @cancel="closeDialog"
    modal-class="task-center-sign-in-success"
    :mask-closable="false"
    :render-to-body="true"
    :footer="false"
    width="343px"
    :title-align="'center'"
    unmount-on-close
  >
    <template #title> </template>
    <div class="content-container">
      <div class="card-container">
        <div class="hover-light showsUp" style="bottom: 0">
          <img :src="imageUrl" />
        </div>
      </div>
      <div class="sign-in-success-container">
        <div class="title">
          <div style="margin-right: 4px">
            <img src="@/assets/star-small.png" style="margin-top: 8px" />
            <img src="@/assets/star-big.png" style="margin-bottom: 4px" />
          </div>
          Congratulations!
          <div style="margin-left: 4px">
            <img src="@/assets/star-big.png" style="margin-top: 4px" />
            <img src="@/assets/star-small.png" style="margin-bottom: 8px" />
          </div>
        </div>
        <!-- <div class="context">{{ gems }} Gems were added !</div> -->
        <div class="context">Gems were added !</div>
      </div>
      <div class="back-button-container">
        <div class="confirming-button" v-if="!isTodayCheckin">
          <div class="background-container" @click="closeDialog">
            <div class="top-background">Got it</div>
            <div class="bottom-background"></div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { computed } from "vue";
import { useClaimSuccessDialog } from "@/dappos/hooks/useClaimSuccessDialog";
import ClaimImage from "@/assets/claimSuccess.png";

// const { close, isOpen: isDisplay, gems } = useClaimSuccessDialog();
const { close, isOpen: isDisplay } = useClaimSuccessDialog();

const getImageName = () => {
  return ClaimImage;
};

function closeDialog() {
  close();
}

const imageUrl = computed(() => {
  return getImageName();
});
</script>
<style lang="scss" scoped>
@import "@/styles/confirmbutton.scss";
.task-center-sign-in-success-cell {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  span {
    margin-left: 10px;
    color: black;
    font-size: 14px;
  }
  &:hover {
    background-color: rgb(45 45 45 / 10%);
  }
}
.content-container {
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in-success-container {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    color: #000;
    font-style: italic;
    width: 232px;
    height: 32px;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .context {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
}
.task-center-modal-icon {
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 50%;
  padding: 1px;
  background-color: white;
  width: 24px;
  height: 24px;
  .icon {
    border-radius: 50%;
    width: 22px;
  }
}
.back-button-container {
  margin-top: 35px;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  .back-button {
    width: 163px;
    height: 46px;
    border-radius: 100em;
    font-size: 18px;
    font-weight: 500;
    background-color: #33005c;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.7;
  }
}
.card-container {
  position: relative;
  background: url("@/assets/astrolabium.png") no-repeat;
  background-size: 100% 100%;
  width: 225px;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hover-light {
  overflow: hidden;
}
.hover-light.showsUp {
  animation: showsUpAnimation 1.5s linear;
}

.hover-light::after {
  content: "";
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
  background-image: linear-gradient(
    to right,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
  -o-transform: skewx(-25deg);
  -moz-transform: skewx(-25deg);
  -webkit-transform: skewx(-25deg);
  transform: skewx(-25deg);
  animation: cardLightAnimation 1s linear infinite;
  animation-delay: 1s;
}

@keyframes cardLightAnimation {
  from {
    transition: all 0.5s ease;
    left: -100%;
  }
  to {
    transition: none;
    left: 300%;
  }
}

@keyframes showsUpAnimation {
  0% {
    opacity: 0;
    scale: 0;
  }
  25% {
    scale: 1.1;
    opacity: 1;
  }
  50% {
    scale: 0.9;
  }
  100% {
    scale: 1;
  }
}
</style>
