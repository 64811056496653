import { useStorage } from "@vueuse/core";
import { defineStore, storeToRefs } from "pinia";
import axios from "axios";

export const LocalStorage = defineStore("LocalStorage", () => {
  const walletVersion = useStorage(StorageKeys.virtualWalletVersion, null);
  const isDisConnect = useStorage(StorageKeys.isDisConnect, false);
  const referralCode = useStorage(StorageKeys.refKey, null);
  const referralCode_v2 = useStorage(StorageKeys.refKey_v2, null);
  const selfReferralCode = useStorage(StorageKeys.refKey_self, null);
  const lastConnector = useStorage(StorageKeys.lastConnector, null);
  const guideHidden = useStorage(StorageKeys.goit, null);
  const cubistNewer = useStorage(StorageKeys.cubistNewer, null);
  const cubistLoginType = useStorage(StorageKeys.cubistLoginType, null);
  const connectorFrom = useStorage(StorageKeys.connectorFrom, null);

  return {
    lastConnector,
    isDisConnect,
    walletVersion,
    referralCode,
    referralCode_v2,
    selfReferralCode,
    guideHidden,
    cubistNewer,
    cubistLoginType,
    connectorFrom,
  };
});

export const useStorageStore = () => {
  const store = LocalStorage();
  const storage = storeToRefs(store);
  const BASE_URL = "https://invitation.dappos.com";
  const getInviteCode = async (address) => {
    return axios
      .get(`${BASE_URL}/dapposinvitation/invite_code/get_invite_code?`, {
        params: {
          eoaAddress: address,
        },
      })
      .then((response) => {
        if (response.data) {
          storage.selfReferralCode.value = response.data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const bindInvite = async (referrerCode, currentAddress) => {
    axios
      .post(`${BASE_URL}/dapposinvitation/invite_relation/binding`, {
        referrerCode: referrerCode,
        app: "QuickSwap",
        currentAddress: currentAddress,
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return {
    backToNormalMode: store.backToNormalMode,
    storage: storage,
    store: store,
    getInviteCode,
    bindInvite,
  };
};

const StorageKeys = {
  virtualWalletVersion: "dAppos_virtualWallet_version",
  lastConnector: "dAppos_connector_lastConnector",
  isDisConnect: "dAppos_connector_isDisConnect",
  refKey: "DAPPOS_referralCode",
  refKey_v2: "DAPPOS_referralCode_v2",
  refKey_self: "DAPPOS_referralCode_self",
  goit: "dappos_guide_hidden",
  cubistNewer: "cubistNewer",
  cubistLoginType: "cubistLoginType",
  connectorFrom: "dappos_connector_from",
};
