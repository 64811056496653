import { ethers } from "ethers";
import { getEvmChainParameter, networks } from "@/constants";
import { ProviderNotFoundError } from "@/modules/connector";

/**
 * MetaMask
 * Docs: https://docs.metamask.io/guide/ethereum-provider.html
 * JSON RPC API: https://metamask.github.io/api-playground/api-documentation
 */
// export interface MetaMaskProvider extends MetaMaskEthereumProvider {
//   isMetaMask: boolean;
//   providers?: MetaMaskProvider[];
//   isConnected: () => boolean;
//   request: (request: { method: string; params?: any[] | undefined }) => Promise<any>;
//   selectedAddress: string;
// }

/**
 * source: @metamask/detect-provider
 * https://github.com/MetaMask/detect-provider/blob/main/src/index.ts
 */
// export interface MetaMaskEthereumProvider {
//   isMetaMask?: boolean;
//   once(eventName: string | symbol, listener: (...args: any[]) => void): this;
//   on(eventName: string | symbol, listener: (...args: any[]) => void): this;
//   off(eventName: string | symbol, listener: (...args: any[]) => void): this;
//   addListener(eventName: string | symbol, listener: (...args: any[]) => void): this;
//   removeListener(eventName: string | symbol, listener: (...args: any[]) => void): this;
//   removeAllListeners(event?: string | symbol): this;
// }

// export interface Window {
//   ethereum?: MetaMaskProvider;
// }

export const ethereum = window.ethereum;

export class MetaMaskConnector {
  provider;
  connectOpt;
  #onDisconnectHandler;
  #onAccountsChangedHandler;
  #onChainChangedHandler;

  constructor(opt) {
    this.connectOpt = {
      autoConnect: opt?.autoConnect ?? true,
    };
    if (typeof window !== "undefined" && !!ethereum) {
      const _provider = new ethers.providers.Web3Provider(ethereum);
      this.provider = _provider;
      ethereum?.removeAllListeners();
    } else {
      throw new ProviderNotFoundError();
    }
  }

  async connect() {
    console.log("this.provider", this.provider);
    if (!this.provider) {
      throw new ProviderNotFoundError();
    }
    const [accounts, network] = await Promise.all([
      this.provider.send("eth_requestAccounts", []),
      Promise.race([
        this.provider.send("eth_chainId", []).then((e) => {
          return Number(e);
        }),
        this.provider.getNetwork().then((e) => e.chainId),
      ]).then((e) => {
        return Number(e);
      }),
    ]);

    console.log("accounts", accounts, "networks", networks);

    return {
      accounts,
      provider: this.provider,
      network,
    };
  }
  async disconnect() {
    return;
  }

  async isConnected() {
    const accounts = await ethereum?.request({
      method: "eth_accounts",
    });
    return !!accounts[0];
  }

  /**
   * @note MetaMask disconnect event would be triggered when the specific chain changed (like L2 network),
   * and will not be triggered when a user clicked disconnect in wallet...
   */
  // onDisconnect(handler) {
  // if (!this.provider) throw new ProviderNotFoundError();
  // if (this.#onDisconnectHandler) {
  //   this.#removeListener('disconnect', this.#onDisconnectHandler);
  // }
  // this.#onDisconnectHandler = handler;
  // ethereum!.on('disconnect', () => {
  //   console.log('event disconnect');
  //   handler();
  // });
  // }

  onAccountsChanged(handler) {
    if (!this.provider) throw new ProviderNotFoundError();
    if (this.#onAccountsChangedHandler) {
      this.#removeListener("accountsChanged", this.#onAccountsChangedHandler);
    }
    this.#onAccountsChangedHandler = handler;
    ethereum.on("accountsChanged", handler);
  }

  onChainChanged(handler) {
    if (!this.provider) throw new ProviderNotFoundError();
    if (this.#onChainChangedHandler) {
      this.#removeListener("chainChanged", this.#onChainChangedHandler);
    }
    this.#onChainChangedHandler = handler;
    ethereum?.on("chainChanged", (chainId) => {
      this.provider = new ethers.providers.Web3Provider(ethereum);
      handler(chainId);
    });
  }

  #removeListener(event, handler) {
    if (!this.provider) throw new ProviderNotFoundError();
    ethereum?.removeListener(event, handler);
  }

  async switchChain(chainId) {
    // TODO:
    if (!this.provider) throw new ProviderNotFoundError();
    const evmParameter = getEvmChainParameter(chainId);

    try {
      await this.provider.send("wallet_switchEthereumChain", [
        { chainId: evmParameter.chainId },
      ]);
    } catch (err) {
      if (err.code === 4902) {
        try {
          const evmParameter = getEvmChainParameter(chainId);

          await this.addChain(evmParameter);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  async addChain(networkDetails) {
    if (!this.provider) throw new ProviderNotFoundError();
    try {
      this.provider.send("wallet_addEthereumChain", [networkDetails]);
    } catch (err) {
      throw Error("wallet_addEthereumChain \n");
    }
  }
}
