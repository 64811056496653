import { useLoading } from "@/dappos/hooks/useLoading";
import { appName, AddressZero, serviceAddressMap } from "@/constants";
import { usePackedSdk } from "@/dappos/utils/usePackedSdk";
import { useWallet } from "@/dappos/hooks/useWallet";
import { computed } from "vue";
import { Actions, amountZeroFilter, getExpTime } from "@/dappos/service/utils";
import { ethers } from "ethers";
import { getVirtualWallet } from "@/dappos/utils/getVirtualWallet";

export const useTellerXService = () => {
  const {
    loading: signInLoading,
    startLoading: startSignInLoading,
    endLoading: endSignInLoading,
  } = useLoading();

  const {
    loading: claimRewardsInLoading,
    startLoading: startClaimRewardsLoading,
    endLoading: endClaimRewardsLoading,
  } = useLoading();

  const { isIsolated, eoaAddress, dstChainId } = useWallet();

  const { sendTransaction } = usePackedSdk();

  const signIn = async (args, isSimulated) => {
    startSignInLoading();
    const { reward, nonce, validatorSignature } = args;
    const virtualWallet = await getVirtualWallet(
      eoaAddress.value,
      dstChainId.value
    );
    console.log("args: ", args);
    const gasLimit = "300000";

    let types = ["uint256", "uint256", "bytes"];

    let values = [reward, nonce, validatorSignature];

    const executeData = ethers.utils.defaultAbiCoder.encode(types, values);

    const gasToken = AddressZero;

    const cparam = {
      action: Actions.signIn,
      gasToken: gasToken,
      remainGas: "0",
    };

    const params = {
      commonParam: cparam,
      app: appName,
      expTime: getExpTime(),
      service: serviceAddressMap[dstChainId.value],
      text: "Sign In",
      isGateWay: 0,
      gasLimit,
      bridgeTokenOuts: amountZeroFilter([], isSimulated),
      data: executeData,
      virtualWallet,
      useIsolate: isIsolated.value,
    };

    const result = await sendTransaction(params, isSimulated, "Sign In").catch(
      (error) => {
        endSignInLoading();
        throw error;
      }
    );

    endSignInLoading();
    return result;
  };

  const claimRewards = async (args, isSimulated) => {
    startClaimRewardsLoading();
    const { reward, nonce, validatorSignature } = args;
    const virtualWallet = await getVirtualWallet(
      eoaAddress.value,
      dstChainId.value
    );
    console.log("args: ", args);
    const gasLimit = "300000";

    let types = ["uint256", "uint256", "bytes"];

    let values = [reward, nonce, validatorSignature];

    const executeData = ethers.utils.defaultAbiCoder.encode(types, values);

    const gasToken = AddressZero;

    const cparam = {
      action: Actions.claimRewards,
      gasToken: gasToken,
      remainGas: "0",
    };

    const params = {
      commonParam: cparam,
      app: appName,
      expTime: getExpTime(),
      service: serviceAddressMap[dstChainId.value],
      text: "Claim Rewards",
      isGateWay: 0,
      gasLimit,
      bridgeTokenOuts: amountZeroFilter([], isSimulated),
      data: executeData,
      virtualWallet,
      useIsolate: isIsolated.value,
    };

    const result = await sendTransaction(
      params,
      isSimulated,
      "Claim Rewards"
    ).catch((error) => {
      endClaimRewardsLoading();
      throw error;
    });

    endClaimRewardsLoading();
    return result;
  };

  const loading = computed(() => {
    return signInLoading.value || claimRewardsInLoading.value;
  });
  return {
    loading,
    signIn,
    claimRewards,
  };
};
