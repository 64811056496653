<template>
  <div
    class="detail-container"
    :style="{ backgroundColor: platform === 'dappos' ? '#DDFFF3' : '#3A2259' }"
  >
    <div class="content">
      <template v-if="platform === 'dappos'">
        <img src="@/assets/d1@2x.jpg" />
        <img src="@/assets/d2@2x.jpg" />
        <img src="@/assets/d3@2x.jpg" />
      </template>
      <template v-if="platform === 'quickswap'">
        <img src="@/assets/q1@2x.jpg" />
        <img src="@/assets/q2@2x.jpg" />
        <img src="@/assets/q3@2x.jpg" />
        <img src="@/assets/q4@2x.jpg" />
        <img src="@/assets/q5@2x.jpg" />
        <img src="@/assets/q6@2x.jpg" />
        <img src="@/assets/q7@2x.jpg" />
      </template>
      <div
        v-if="currentTask"
        style="height: 65px; width: 100%"
        :style="{
          backgroundColor: platform === 'quickswap' ? 'white' : 'transparent',
        }"
      ></div>
    </div>
    <div class="fixed-button" v-if="currentTask">
      <div class="background-container">
        <div class="top-background" @click="claim" v-if="showClaimButton">
          Unlocked {{ currentTask.gems }} Gems
        </div>
        <div class="top-background" @click="jumpLink" v-if="showUnlockButton">
          Take On The Challenge
        </div>
        <div class="top-background disabled" v-if="showCompleted">
          Task Completed
        </div>
        <div
          class="bottom-background"
          :style="{
            backgroundColor: platform === 'dappos' ? '#68C2A2' : '#3478FF',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./index.scss";
</style>
<script setup>
import { useRoute } from "vue-router";
import { useDataLoader } from "@/hooks/useDataLoader";
import { useWallet } from "@/dappos/hooks/useWallet";
import { watchEffect, computed } from "vue";
import { useActionCaller } from "@/hooks/useActionCaller";
import TriggerEvent from "@/dappos/utils/triggerEvent";
const { vwAddress, eoaAddress } = useWallet();

const route = useRoute();
const platform = route.query.platform;
const { taskList, loadTaskList } = useDataLoader();
const { callClaimRewards } = useActionCaller();

const currentTask = computed(() => {
  return taskList.value.find(
    (i) => i.name.toLowerCase() === platform.toLowerCase()
  );
});

const showClaimButton = computed(() => {
  return (
    currentTask.value && currentTask.value.done && !currentTask.value.is_claim
  );
});

const showUnlockButton = computed(() => {
  return (
    currentTask.value && !currentTask.value.done && !currentTask.value.is_claim
  );
});

const showCompleted = computed(() => {
  return (
    currentTask.value && currentTask.value.done && currentTask.value.is_claim
  );
});

const jumpLink = () => {
  if (currentTask.value.name.toLowerCase() === "quickswap") {
    window.open(
      "https://quickswap.dappos.com/pools/trade?refCode=eq1che6e&connector=cubist&channel=google",
      "_blank"
    );
  } else {
    window.open("https://wallet.dappos.com", "_blank");
  }
};
const claim = () => {
  const item = currentTask.value;
  const gems = item.gems;
  const task = item.name.toLowerCase();
  callClaimRewards(gems, task);
};

TriggerEvent.on("loadHomeInfo", () => {
  loadTaskList(eoaAddress.value, vwAddress.value);
});

watchEffect(() => {
  loadTaskList(eoaAddress.value, vwAddress.value);
});
</script>
