<template>
  <div
    class="task-center-header"
    :style="{
      backgroundColor: bgColor,
    }"
  >
    <div class="header-left" @click="back">
      <div class="back-icon">
        <img src="@/assets/back-icon.png" />
      </div>
      <div
        v-if="!(accountConnected && transactionState.isPolling)"
        class="title"
      >
        Task Center
      </div>
    </div>
    <div class="header-right">
      <switchNetwork v-if="accountConnected" />
      <div
        class="wallet-connect-button"
        v-if="!accountConnected"
        @click="connectWallet"
      >
        <div class="network-button">
          <div class="network-icon">
            <img :src="getEvmChainParameter(137).iconUrls[0]" />
          </div>
        </div>

        Connect
      </div>
      <OsCard v-else />
      <TransactionPending />
    </div>
  </div>
</template>
<style lang="scss">
@import "./index.scss";
</style>
<script setup>
import { computed } from "vue";
import OsCard from "@/dappos/components/osCard.vue";
import { useWalletConnectorDialog } from "@/dappos/hooks/useWalletConnectorDialog";
import { useEthereumStore } from "@/stores/ethereum.store";
import switchNetwork from "@/dappos/components/switchNetwork";
import { useTransactionsStore } from "@/stores/transactions.store";
import TransactionPending from "@/dappos/components/transactionPending";
import { getEvmChainParameter } from "@/constants";

import { useHomeBgcolor } from "@/hooks/useHomeBgColor";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const { bgColor } = useHomeBgcolor();

const { transactionsState: state } = useTransactionsStore();
const transactionState = computed(() => state.transactionState.value);

const { ethereumState } = useEthereumStore();
const { accountConnected } = ethereumState;
const connectWallet = () => {
  console.log("connectWallet");
  const { open } = useWalletConnectorDialog();
  open();
};
const back = () => {
  if (route.path === "/") {
    window.open("guruji://main/me", "_blank");
    window.close();
  } else {
    router.back();
  }
};
</script>
