import { getProvider } from "@/composables/use-provider";
import { getEvmChainParameter } from "@/constants";

export async function switchChain(chainId) {
  // TODO:
  const evmParameter = getEvmChainParameter(chainId);
  const provider = getProvider();
  try {
    await provider
      .send("wallet_switchEthereumChain", [{ chainId: evmParameter.chainId }])
      .then((e) => {
        console.log(e);
      });
  } catch (err) {
    if (err.code === 4902) {
      try {
        const evmParameter = getEvmChainParameter(chainId);

        await addChain(evmParameter);
      } catch (err) {
        console.error(err);
      }
    }
    throw err;
  }
}

export async function addChain(networkDetails) {
  const provider = getProvider();

  try {
    await provider.send("wallet_addEthereumChain", [networkDetails]);
  } catch (err) {
    throw Error("wallet_addEthereumChain \n");
  }
}
