import { AddressZero } from "@/constants";
export const Actions = {
  signIn: 9,
  claimRewards: 10,
};

export const getExpTime = () => {
  return Math.floor((new Date().getTime() + 60 * 60 * 24 * 1000) / 1000);
};

export const amountZeroFilter = (arr, isSimulated) => {
  if (isSimulated) return arr;
  return arr.filter((item) => Number(item["amountOut"]) > 0);
};

export const forbidenNativeTokenEoa = () => {
  return {
    eoaAccount: {
      excludeTokens: [
        {
          chainId: 1,
          address: AddressZero,
        },
      ],
    },
  };
};
