<template>
  <div class="card-slider-container">
    <div
      class="card-slider-0 card-container"
      :class="{ unchecked: !signList[0].is_checkin }"
      :style="cardSlider0Style"
    >
      <!-- <div class="card-boarder"></div> -->
      <div
        class="card"
        :class="cardClass0"
        @click="sliderClicked(0)"
        @mouseenter="mouseEnterFn(0)"
        @mouseleave.stop="mouseLeaveFn(0)"
      >
        <div class="bonus-container normal">
          <div class="top">+{{ signList[0].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[0].is_checkin }">
            <img v-if="signList[0].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[0].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-slider-1 card-container"
      :class="{ unchecked: signList[0].is_checkin && !signList[1].is_checkin }"
      :style="cardSlider1Style"
    >
      <!-- <div class="card-boarder"></div> -->
      <div
        class="card"
        :class="cardClass1"
        @click="sliderClicked(1)"
        @mouseenter="mouseEnterFn(1)"
        @mouseleave.stop="mouseLeaveFn(1)"
      >
        <div class="bonus-container normal">
          <div class="top">+{{ signList[1].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[1].is_checkin }">
            <img v-if="signList[1].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[1].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-slider-2 card-container"
      :class="{ unchecked: signList[1].is_checkin && !signList[2].is_checkin }"
      :style="cardSlider2Style"
    >
      <!-- <div class="card-boarder"></div> -->
      <div
        class="card"
        :class="cardClass2"
        @click="sliderClicked(2)"
        @mouseenter="mouseEnterFn(2)"
        @mouseleave.stop="mouseLeaveFn(2)"
      >
        <div class="bonus-container normal">
          <div class="top">+{{ signList[2].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[2].is_checkin }">
            <img v-if="signList[2].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[2].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-slider-3 card-container"
      :class="{ unchecked: signList[2].is_checkin && !signList[3].is_checkin }"
      :style="cardSlider3Style"
    >
      <!-- <div class="card-boarder"></div> -->
      <div
        class="card"
        :class="cardClass3"
        @click="sliderClicked(3)"
        @mouseenter="mouseEnterFn(3)"
        @mouseleave.stop="mouseLeaveFn(3)"
      >
        <div class="bonus-container normal">
          <div class="top">+{{ signList[3].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[3].is_checkin }">
            <img v-if="signList[3].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[3].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-slider-4 card-container"
      :class="{ unchecked: signList[3].is_checkin && !signList[4].is_checkin }"
      :style="cardSlider4Style"
    >
      <!-- <div class="card-boarder"></div> -->
      <div
        class="card"
        :class="cardClass4"
        @click="sliderClicked(4)"
        @mouseenter="mouseEnterFn(4)"
        @mouseleave.stop="mouseLeaveFn(4)"
      >
        <div class="bonus-container normal">
          <div class="top">+{{ signList[4].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[4].is_checkin }">
            <img v-if="signList[4].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[4].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-slider-5 card-container"
      :class="{ unchecked: signList[4].is_checkin && !signList[5].is_checkin }"
      :style="cardSlider5Style"
    >
      <!-- <div class="card-boarder"></div> -->
      <div
        class="card"
        :class="cardClass5"
        @click="sliderClicked(5)"
        @mouseenter="mouseEnterFn(5)"
        @mouseleave.stop="mouseLeaveFn(5)"
      >
        <div class="bonus-container normal">
          <div class="top">+{{ signList[5].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[5].is_checkin }">
            <img v-if="signList[5].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[5].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-slider-6 card-container"
      :class="{ unchecked: signList[5].is_checkin && !signList[6].is_checkin }"
      :style="cardSlider6Style"
    >
      <!-- <div class="card-bo arder"></div> -->
      <div
        class="card"
        :class="cardClass6"
        @click="sliderClicked(6)"
        @mouseenter="mouseEnterFn(6)"
        @mouseleave.stop="mouseLeaveFn(6)"
      >
        <div class="bonus-container special">
          <div class="top">+{{ signList[6].gems }}Gems</div>
          <div class="bottom" :class="{ checked: signList[6].is_checkin }">
            <img v-if="signList[6].is_checkin" src="@/assets/check-mark.png" />
            <template v-else>{{ signList[6].checkdate }}</template>
          </div>
        </div>
      </div>
    </div>
    <div class="bar left-bar" @click="leftClicked">
      <img src="@/assets/back-icon.png" />
    </div>
    <div class="bar right-bar" @click="rightClicked">
      <img src="@/assets/back-icon.png" />
    </div>
  </div>
</template>
<style lang="scss">
@import "./index.scss";
</style>
<script setup>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watchEffect,
  h,
  watch,
  defineEmits,
} from "vue";
import { useHomeBgcolor } from "@/hooks/useHomeBgColor";
import { useServiceCaller } from "@/dappos/hooks/useServiceCaller";
import { gurujiDomain } from "@/constants";
import { useWallet } from "@/dappos/hooks/useWallet";
import SuccessSvg from "@/assets/img/svg/success.svg";
import ErrorSvg from "@/assets/img/svg/error.svg";
import axios from "axios";
import TriggerEvent from "@/dappos/utils/triggerEvent";
import { Notification } from "@arco-design/web-vue";
import { useSignInSuccessDialog } from "@/dappos/hooks/useSignInSuccessDialog";
import { useDataLoader } from "@/hooks/useDataLoader";
import { useNeedConnectWalletDialog } from "@/dappos/hooks/useNeedConnectWalletDialog";
const { isConnected, open: openNeedConnectWalletDialog } =
  useNeedConnectWalletDialog();

const { open } = useSignInSuccessDialog();

const emit = defineEmits(["onConsecutiveDaysChanged"]);

const todayTimestamp = Number((Number(new Date()) / 1000).toFixed());

const { getIsTodayCheckedIn } = useDataLoader();

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  console.log(timestamp, date, date.getMonth());
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return (
    (day.toString().length === 1 ? "0" + day : day) +
    "/" +
    (month.toString().length === 1 ? "0" + month : month)
  );
};

const signList = ref([
  {
    checkdate: formatDate(todayTimestamp),
    gems: 1,
    is_checkin: 0,
    clicked: true,
  },
  {
    checkdate: formatDate(todayTimestamp + 86400),
    gems: 2,
    is_checkin: 0,
    clicked: true,
  },
  {
    checkdate: formatDate(todayTimestamp + 86400 * 2),
    gems: 3,
    is_checkin: 0,
    clicked: true,
  },
  {
    checkdate: formatDate(todayTimestamp + 86400 * 3),
    gems: 4,
    is_checkin: 0,
    clicked: true,
  },
  {
    checkdate: formatDate(todayTimestamp + 86400 * 4),
    gems: 5,
    is_checkin: 0,
    clicked: true,
  },
  {
    checkdate: formatDate(todayTimestamp + 86400 * 5),
    gems: 6,
    is_checkin: 0,
    clicked: true,
  },
  {
    checkdate: formatDate(todayTimestamp + 86400 * 6),
    gems: 14,
    is_checkin: 0,
    clicked: true,
  },
]);
const { eoaAddress, vwAddress, isReady } = useWallet();

watch(
  signList,
  () => {
    emit(
      "onConsecutiveDaysChanged",
      signList.value.filter((i) => !!i.is_checkin).length
    );
  },
  {
    immediate: true,
    deep: true,
  }
);

const loadSignList = async (eoa, vw) => {
  const url = `${gurujiDomain}/solar/checkin/get_checkin_list`;
  const res = await axios.get(url, {
    params: { eoaAddress: eoa, vwAddress: vw },
  });
  if (res.data?.data?.chinkin_list) {
    const list =
      res.data?.data?.chinkin_list.map((i) => {
        return {
          ...i,
          checkdate:
            i.checkin_date.toString().substr(6, 2) +
            "/" +
            i.checkin_date.toString().substr(4, 2),
          clicked: true,
        };
      }) ?? [];
    signList.value = list;
    initDeg();
  }
};

const initDeg = () => {
  if (mode.value === "mobile") {
    resetAllRotate();
    const item = signList.value.filter((i) => !i.is_checkin)[0];
    const index = signList.value.indexOf(item);
    const degNum = index * 5 - 15;
    rotate0Deg.value -= degNum;
    rotate1Deg.value -= degNum;
    rotate2Deg.value -= degNum;
    rotate3Deg.value -= degNum;
    rotate4Deg.value -= degNum;
    rotate5Deg.value -= degNum;
    rotate6Deg.value -= degNum;
  }
};

const load = () => {
  loadSignList(eoaAddress.value, vwAddress.value);
};

watchEffect(() => {
  loadSignList(eoaAddress.value, vwAddress.value);
});

const { setBgColor, resetColor } = useHomeBgcolor();

const { startTransactionProcess } = useServiceCaller();

const sliderClicked = (index) => {
  console.log(index);
  // get first of list that is not checkin
  const item = signList.value.filter((i) => !i.is_checkin)[0];
  if (item?.clicked) {
    callSignIn(item);
  }
};

TriggerEvent.on("checkinCommit", () => {
  sliderClicked(0);
});

const callSignIn = async (item) => {
  if (!isConnected.value) {
    openNeedConnectWalletDialog();
    return;
  }
  const gems = item.gems;
  const checkdate = item.checkdate;
  const index = signList.value.indexOf(item);
  const totalGems =
    signList.value
      .filter((i) => i.is_checkin)
      .reduce((sum, i) => (sum += Number(i.gems)), 0) + Number(gems);
  const tomorrowGems = (signList.value[index + 1] ?? signList.value[0]).gems;
  const isSpecial = index === 6;
  console.log("index ===", index);
  if (!(isReady.value && vwAddress.value)) {
    return;
  }

  const vw = vwAddress.value;
  const eoa = eoaAddress.value;

  const todayIsCheckIn = await getIsTodayCheckedIn(eoa, vw);

  if (todayIsCheckIn) {
    Notification.error({
      title: `Reward claimed, See you tomorrow`,
      content: null,
      duration: 1000 * 10.33,
      closable: true,
      showIcon: true,
      icon: h("img", { src: ErrorSvg }),
      class: "dappos_notify",
    });
    return;
  }

  // get signature from api
  const getSignature = async () => {
    const url = `${gurujiDomain}/solar/dappos/task_record_signature`;
    const res = await axios.get(url, {
      params: { gems, vwAddress: eoa }, // ! because new user's vw is 0x0...000 after register, use eoa to get signature
    });
    return res.data?.data?.record_data ?? {};
  };

  const { signature: validatorSignature, nonce, reward } = await getSignature();

  // call contract
  const res = await startTransactionProcess(
    "signIn",
    {
      reward,
      nonce,
      validatorSignature,
    },
    false,
    "Sign In"
  );

  if (res?.isSuccess) {
    // call callback api
    const signCallback = async () => {
      const url = `${gurujiDomain}/solar/checkin/checkin_callback`;
      const res = await axios.get(url, {
        params: { gems, vwAddress: vw, eoaAddress: eoa },
      });
      return res.data.data;
    };
    const res = await signCallback();
    if (!res.code) {
      open({ index, gems, totalGems, tomorrowGems, checkdate, isSpecial });
      Notification.success({
        title: `Checkin Success`,
        content: null,
        duration: 1000 * 10.33,
        closable: true,
        showIcon: true,
        icon: h("img", { src: SuccessSvg }),
        class: "dappos_notify",
      });
    } else {
      Notification.error({
        title: `Checkin failure`,
        content: null,
        duration: 1000 * 10.33,
        closable: true,
        showIcon: true,
        icon: h("img", { src: ErrorSvg }),
        class: "dappos_notify",
      });
    }
  }
  TriggerEvent.emit("loadHomeInfo");
  load();
};

// below is animation
let timer;
const cardClass0 = ref(["opacity-0"]);
const cardClass1 = ref(["opacity-0"]);
const cardClass2 = ref(["opacity-0"]);
const cardClass3 = ref(["opacity-0"]);
const cardClass4 = ref(["opacity-0"]);
const cardClass5 = ref(["opacity-0"]);
const cardClass6 = ref(["opacity-0"]);

const rotate0Deg = ref(-15);
const rotate1Deg = ref(-10);
const rotate2Deg = ref(-5);
const rotate3Deg = ref(0);
const rotate4Deg = ref(5);
const rotate5Deg = ref(10);
const rotate6Deg = ref(15);

const transformY0 = ref(0);
const transformY1 = ref(0);
const transformY2 = ref(0);
const transformY3 = ref(0);
const transformY4 = ref(0);
const transformY5 = ref(0);
const transformY6 = ref(0);

const resetAllRotate = () => {
  rotate0Deg.value = -15;
  rotate1Deg.value = -10;
  rotate2Deg.value = -5;
  rotate3Deg.value = 0;
  rotate4Deg.value = 5;
  rotate5Deg.value = 10;
  rotate6Deg.value = 15;

  transformY0.value = 0;
  transformY1.value = 0;
  transformY2.value = 0;
  transformY3.value = 0;
  transformY4.value = 0;
  transformY5.value = 0;
  transformY6.value = 0;
};

// const pauseHangAround = ref(false);

let stepLeft = 1;
let stepRight = 0;
const doLeft = () => {
  if (rotate3Deg.value === 0) {
    rotate6Deg.value -= 3.4;
    setTimeout(() => {
      rotate5Deg.value -= 3.2;
      setTimeout(() => {
        rotate4Deg.value -= 3;
        setTimeout(() => {
          rotate3Deg.value -= 2.8;
          setTimeout(() => {
            rotate2Deg.value -= 2.6;
            setTimeout(() => {
              rotate1Deg.value -= 2.4;
              setTimeout(() => {
                rotate0Deg.value -= 2.2;
              });
            }, 60);
          }, 60);
        }, 60);
      }, 60);
    }, 60);
  } else {
    rotate6Deg.value -= 2.2;
    setTimeout(() => {
      rotate5Deg.value -= 2.4;
      setTimeout(() => {
        rotate4Deg.value -= 2.6;
        setTimeout(() => {
          rotate3Deg.value -= 2.8;
          setTimeout(() => {
            rotate2Deg.value -= 3;
            setTimeout(() => {
              rotate1Deg.value -= 3.2;
              setTimeout(() => {
                rotate0Deg.value -= 3.4;
              }, 60);
            }, 60);
          }, 60);
        }, 60);
      }, 60);
    }, 60);
  }
  stepLeft -= 1;
  if (stepLeft === 0) {
    stepRight = 2;
  }
};
const doRight = () => {
  if (rotate3Deg.value === 0) {
    rotate0Deg.value += 3.4;
    setTimeout(() => {
      rotate1Deg.value += 3.2;
      setTimeout(() => {
        rotate2Deg.value += 3;
        setTimeout(() => {
          rotate3Deg.value += 2.8;
          setTimeout(() => {
            rotate4Deg.value += 2.6;
            setTimeout(() => {
              rotate5Deg.value += 2.4;
              setTimeout(() => {
                rotate6Deg.value += 2.2;
              }, 60);
            }, 60);
          }, 60);
        }, 60);
      }, 60);
    }, 60);
  } else {
    rotate0Deg.value += 2.2;
    setTimeout(() => {
      rotate1Deg.value += 2.4;
      setTimeout(() => {
        rotate2Deg.value += 2.6;
        setTimeout(() => {
          rotate3Deg.value += 2.8;
          setTimeout(() => {
            rotate4Deg.value += 3;
            setTimeout(() => {
              rotate5Deg.value += 3.2;
              setTimeout(() => {
                rotate6Deg.value += 3.4;
              }, 60);
            }, 60);
          }, 60);
        }, 60);
      }, 60);
    }, 60);
  }
  stepRight -= 1;
  if (stepRight === 0) {
    stepLeft = 2;
  }
};

const mode = ref();

const initTimer = () => {
  if (mode.value === "mobile") return;
  timer = setInterval(() => {
    if (stepRight === 0) {
      doLeft();
    } else if (stepLeft === 0) {
      doRight();
    }
  }, 2000);
};

const clearTimer = () => {
  clearInterval(timer);
  timer = null;
};

const resizeFunction = () => {
  if (document.documentElement.clientWidth <= 900) {
    if (mode.value === "pc") {
      resetAllRotate();
      clearTimer();
    }
    mode.value = "mobile";
  } else {
    if (mode.value === "mobile") {
      resetAllRotate();
      initTimer();
    }
    mode.value = "pc";
  }
};

watchEffect(() => {
  console.log("mode", mode.value);
});

const visibilityChanged = () => {
  if (!document.hidden) {
    // shows up
    initTimer();
  } else {
    // hide
    clearTimer();
  }
};

onMounted(() => {
  mode.value = document.documentElement.clientWidth > 900 ? "pc" : "mobile";
  window.addEventListener("resize", resizeFunction);
  document.addEventListener("visibilitychange", visibilityChanged);
  initTimer();
  initDeg();
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeFunction);
  document.removeEventListener("visibilitychange", visibilityChanged);
  clearTimer();
  resetColor();
});

const cardSlider0Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate0Deg.value +
    "deg) translateY(" +
    transformY0.value +
    ")"
);

const cardSlider1Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate1Deg.value +
    "deg) translateY(" +
    transformY1.value +
    ")"
);

const cardSlider2Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate2Deg.value +
    "deg) translateY(" +
    transformY2.value +
    ")"
);

const cardSlider3Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate3Deg.value +
    "deg) translateY(" +
    transformY3.value +
    ")"
);

const cardSlider4Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate4Deg.value +
    "deg) translateY(" +
    transformY4.value +
    ")"
);

const cardSlider5Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate5Deg.value +
    "deg) translateY(" +
    transformY5.value +
    ")"
);

const cardSlider6Style = computed(
  () =>
    "transform: translateX(-50%) rotate(" +
    rotate6Deg.value +
    "deg) translateY(" +
    transformY6.value +
    ")"
);

const rightClicked = () => {
  if (rotate6Deg.value === 0) {
    return;
  }
  rotate0Deg.value -= 5;
  rotate1Deg.value -= 5;
  rotate2Deg.value -= 5;
  rotate3Deg.value -= 5;
  rotate4Deg.value -= 5;
  rotate5Deg.value -= 5;
  rotate6Deg.value -= 5;
};

const leftClicked = () => {
  if (rotate0Deg.value === 0) {
    return;
  }
  rotate0Deg.value += 5;
  rotate1Deg.value += 5;
  rotate2Deg.value += 5;
  rotate3Deg.value += 5;
  rotate4Deg.value += 5;
  rotate5Deg.value += 5;
  rotate6Deg.value += 5;
};

const showsUp = () => {
  setTimeout(() => {
    cardClass0.value.splice(0, 1);
    cardClass6.value.splice(0, 1);
    setTimeout(() => {
      cardClass1.value.splice(0, 1);
      cardClass5.value.splice(0, 1);
      setTimeout(() => {
        cardClass2.value.splice(0, 1);
        cardClass4.value.splice(0, 1);
        setTimeout(() => {
          cardClass3.value.splice(0, 1);
        }, 150);
      }, 200);
    }, 250);
  }, 300);
};

const mouseEnterFn = (index) => {
  if (mode.value === "mobile") return;
  console.log("mouseEnter: ", index);
  clearTimer();
  switch (index) {
    case 0:
      setBgColor("#DF7800");
      return;
    case 1:
      setBgColor("#1874CA");
      return;
    case 2:
      setBgColor("#9E3EAA");
      return;
    case 3:
      setBgColor("#52B039");
      return;
    case 4:
      setBgColor("rgb(161, 33, 17)");
      return;
    case 5:
      setBgColor("#1874CA");
      return;
    case 6:
      setBgColor("#9E3EAA");
      return;
    default:
      return;
  }
};

const mouseLeaveFn = (index) => {
  console.log("mouseLeave: ", index);
  initTimer();
};

showsUp();
</script>
