import { ref, computed } from "vue";
import { useEthereumStore } from "@/stores/ethereum.store";
import { useWalletConnectorDialog } from "./useWalletConnectorDialog";

const isOpen = ref(false);

export function useNeedConnectWalletDialog() {
  const { ethereumState } = useEthereumStore();
  const { accountConnected } = ethereumState;
  const { open: openConnectorDialog } = useWalletConnectorDialog();

  const isConnected = computed(() => {
    return accountConnected.value;
  });

  const open = () => {
    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  const connect = () => {
    close();
    openConnectorDialog();
  };

  return {
    isOpen,
    isConnected,
    open,
    close,
    connect,
  };
}
