import { VirtualWallet } from "@dappos/v2-sdk";
import { markRaw } from "vue";
import { getProvider } from "@/composables/use-provider";

class VirtualWalletCache {
  _cache = {};
  get cache() {
    return this._cache;
  }

  getItem(key) {
    return this.cache[key];
  }

  setItem(key, value) {
    this.cache[key] = value;
  }

  // {ownerAddress}-{dstChain}:{VirtualWallet}
  has(key) {
    return !!this.cache[key];
  }

  removeItem(key) {
    this.cache[key] = undefined;
  }

  clear() {
    this._cache = {};
  }
}

export const vWalletCache = new VirtualWalletCache();

export const getVirtualWallet = async (
  owner,
  chainId,
  { cache } = { cache: true }
) => {
  // if (!owner) throw Error("owner is required");
  if (!owner) return;
  const lowerAddress = owner.toLowerCase();
  const key = `${lowerAddress}-${chainId}`;
  if (cache) {
    if (vWalletCache.has(key)) {
      const cacheVirtual = vWalletCache.getItem(key);
      if (
        cacheVirtual.walletCreated &&
        cacheVirtual.owner.toLowerCase() === lowerAddress &&
        cacheVirtual.chainId == chainId
      ) {
        return cacheVirtual;
      }
    }
  }

  const virtualWallet = await VirtualWallet.init(lowerAddress, {
    env: process.env.VUE_APP_DAPPOS_ENV,
    provider: getProvider(chainId),
    chainId,
    version: "2",
  });
  if (virtualWallet.walletCreated) {
    vWalletCache.setItem(key, virtualWallet);
    return markRaw(virtualWallet);
  }
  return markRaw(virtualWallet);
};
