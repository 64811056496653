<template>
  <a-modal
    :visible="isOpen"
    @cancel="closeModal"
    modal-class="task-center-leverage-modal"
    :mask-closable="false"
    :render-to-body="true"
    :footer="false"
    width="343px"
    :title-align="'center'"
    unmount-on-close
  >
    <template #title> Transaction Submitted </template>
    <div>
      <a-space direction="vertical" :size="[0, 30]" fill>
        <div class="task-center-modal-icon">
          <img class="icon" src="@/dappos/assets/arrow-up.svg" />
        </div>
        <div class="task-center-modal-content" @click="getModel">
          <span>View on Explorer</span>
          <icon-arrow-right class="icon-arrow-right" size="14" />
        </div>
        <a-button
          class="close-button"
          type="primary"
          size="large"
          long
          @click="close"
        >
          Close
        </a-button>
      </a-space>
    </div>
  </a-modal>
</template>
<script setup>
import { useTxDialog } from "@/dappos/hooks/useOrderDialog";

const { close, currentOrder, isOpen } = useTxDialog();

function closeModal() {
  close();
}

const getModel = () => {
  window.open(
    `${process.env.VUE_APP_DAPPOS_EXPLORER_HOST}/activities/details?order_hash=${currentOrder.value?.orderHash}`
  );
  close();
};
</script>
<style lang="scss" scoped>
.task-center-modal-icon {
  display: flex;
  justify-content: center;
  .icon {
    width: 42px;
    height: 42px;
  }
}
.task-center-modal-content {
  text-align: center;
  cursor: pointer;
  //   color: rgba(8, 195, 142, 1);
  color: black;
  span {
    font-size: 14px;
  }
}
.icon-arrow-right {
  margin-left: 2px;
  transform: rotate(-45deg);
}
.close-button {
  //   background-color: #2f0654 !important;
  border-radius: 8px;
}
</style>
