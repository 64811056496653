<template>
  <a-modal
    :visible="isDisplay"
    @cancel="closeDialog"
    modal-class="task-center-wallet-connector"
    :mask-closable="false"
    :render-to-body="true"
    :footer="false"
    width="343px"
    :title-align="'center'"
    unmount-on-close
  >
    <template #title> Connect Wallet </template>
    <div>
      <a-space direction="vertical" :size="[0, 10]" fill>
        <div
          v-if="metamask && showWallet && isDev"
          class="task-center-wallet-connector-cell"
          :class="{ loading: loading[IConnector.METAMASK] }"
          @click="connectWallet(IConnector.METAMASK, true)"
        >
          <div class="task-center-modal-icon">
            <img class="icon" src="@/dappos/assets/metamask.svg" />
          </div>
          <span> MetaMask </span>
        </div>
        <a
          v-else-if="showWallet && isDev"
          href="https://metamask.io/"
          class="task-center-wallet-connector-cell"
        >
          <div class="task-center-modal-icon">
            <img class="icon" src="@/dappos/assets/metamask.svg" />
          </div>
          <span> Install MetaMask </span>
        </a>
        <div
          v-if="google && !isMetamaskMobile"
          class="task-center-wallet-connector-cell"
          :class="{ loading: loading[IConnector.GOOGLE] }"
          @click="connectWallet(IConnector.GOOGLE, true)"
        >
          <div class="task-center-modal-icon">
            <img class="icon" src="@/dappos/assets/google.svg" />
            <div class="cubi-icon">
              <img src="@/dappos/assets/cubi.png" />
            </div>
          </div>
          <span> Google </span>
        </div>
        <div
          v-if="false && twitter && !isMetamaskMobile && isDev"
          class="task-center-wallet-connector-cell"
          :class="{ loading: loading[IConnector.TWITTER] }"
          @click="connectWallet(IConnector.TWITTER, true)"
        >
          <div class="task-center-modal-icon">
            <img class="icon" src="@/dappos/assets/twitter.svg" />
            <div class="cubi-icon">
              <img src="@/dappos/assets/cubi.png" />
            </div>
          </div>
          <span> Twitter </span>
        </div>
      </a-space>
    </div>
  </a-modal>
</template>
<script setup>
import { useWalletConnectorDialog } from "@/dappos/hooks/useWalletConnectorDialog";
import { IConnector, useConnector } from "@/composables/use-connector";
import { useStorageStore } from "@/stores/storage.store";
import { isPC } from "@/utils";
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";

const { close, isOpen: isDisplay, open } = useWalletConnectorDialog();

const isDev = process.env.NODE_ENV === "development";

const { connectors, connect } = useConnector();

const isMetamaskMobile = !!window.ethereum && !isPC();

const metamask = connectors.value[IConnector.METAMASK];
const google = connectors.value[IConnector.GOOGLE];
const twitter = connectors.value[IConnector.TWITTER];

const showWallet = ref(true);

const { storage } = useStorageStore();

function closeDialog() {
  showWallet.value = true;
  close();
}

const loading = ref({
  [IConnector.METAMASK]: false,
  [IConnector.WALLET_CONNECT]: false,
  [IConnector.GOOGLE]: false,
  [IConnector.TWITTER]: false,
});

watchEffect(() => {
  console.log("loading", loading.value);
});

const isCubist = (connector) => {
  return (
    connector === IConnector.TWITTER ||
    connector === IConnector.FACEBOOK ||
    connector === IConnector.GOOGLE
  );
};

document.addEventListener("cubist-auth", (e) => {
  const connector = e.detail;
  if (isCubist(connector)) {
    loading.value[connector] = true;
  }
});
const checkIsConnectorConnecting = () => {
  return (
    loading.value[IConnector.METAMASK] ||
    loading.value[IConnector.WALLET_CONNECT] ||
    loading.value[IConnector.GOOGLE] ||
    loading.value[IConnector.TWITTER]
  );
};

const connectWallet = (type, reset) => {
  if (checkIsConnectorConnecting()) {
    return;
  }
  storage.cubistLoginType.value = type;
  if (!isCubist(type)) {
    loading.value[type] = true;
  }
  connect(type, reset)
    .then(() => {
      closeDialog();
    })
    .finally(() => {
      loading.value[type] = false;
    });
};

const router = useRoute();
const query = router.query["connector"];
if (query === "cubist") {
  showWallet.value = false;
  open();
}
</script>
<style lang="scss" scoped>
.task-center-wallet-connector-cell {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  span {
    margin-left: 10px;
    color: black;
    font-size: 14px;
  }
  &:hover {
    background-color: rgb(45 45 45 / 10%);
  }
  .cubi-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    bottom: -2px;
    right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 80%;
      height: 80%;
    }
  }
}
.task-center-modal-icon {
  display: flex;
  position: relative;
  justify-content: center;
  .icon {
    width: 22px;
  }
}
.loading {
  pointer-events: none;
}
.loading:before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  animation-duration: 1s;
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  border-width: 2px;
  animation: spin 1s linear infinite;
  content: "";
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #2f0654;
  border-right-color: #2f0654;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
