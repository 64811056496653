<template>
  <!-- VW dialog -->
  <template v-if="accountConnected">
    <dappos-wallet-lite
      :connector="dataRef"
      size="sm"
      position="bottom-end"
      @version-change="handVersionChange"
      @disconnect="disconnect"
      @deposit="deposit"
    />
  </template>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { EthereumStore, useEthereumStore } from "@/stores/ethereum.store";
import { LocalStorage } from "@/stores/storage.store";
import { mapState } from "pinia";
import { IConnector, useConnector } from "@/composables/use-connector";
import { useProvider } from "@/composables/use-provider";
import { useStorageStore } from "@/stores/storage.store";
import { CubistProvider } from "cubist-provider";
export default defineComponent({
  name: "OSCard",
  setup() {
    const { ethereumStore, ethereumState } = useEthereumStore();
    const { setProvider } = useProvider();
    const { storage } = useStorageStore();

    const { connectors, activeConnector } = useConnector();

    const logoComputed = () => {
      const currentConnector = storage.lastConnector.value;
      if (currentConnector === IConnector.METAMASK) {
        return "https://dappos-public-resource.s3.amazonaws.com/dappLogo/metamask.png";
      } else if (
        currentConnector === IConnector.GOOGLE ||
        currentConnector === IConnector.FACEBOOK ||
        currentConnector === IConnector.TWITTER
      ) {
        return "https://dappos-public-resource.s3.amazonaws.com/dappLogo/cubi.png";
      } else if (currentConnector === IConnector.WALLET_CONNECT) {
        return "https://dappos-public-resource.s3.amazonaws.com/dappLogo/walletconnect.png";
      } else {
        return "https://dappos-public-resource.s3.amazonaws.com/dappLogo/metamask.png";
      }
    };

    const showRed = () => {
      const currentConnector = storage.lastConnector.value;
      if (!currentConnector) {
        return false;
      }
      const isOIDC =
        currentConnector === IConnector.GOOGLE ||
        currentConnector === IConnector.FACEBOOK ||
        currentConnector === IConnector.TWITTER;

      if (!isOIDC) {
        return false;
      }
      console.log("test", currentConnector);

      const isNewer = storage.cubistNewer.value; // false
      const result = `${isNewer}` === `${isOIDC}`; //true
      console.log(`oidc${isOIDC}, isNewer${isNewer}, result: ${result}`);
      return result;
    };

    const chaindId = () => {
      if (!ethereumState.network.value) {
        return 0;
      }
      return parseInt(ethereumState.network.value.chainId);
    };

    const getConnectorName = () => {
      console.log("get connectorName", storage.lastConnector.value);
      return storage.lastConnector.value;
    };

    const dataRef = computed(() => {
      return {
        showRed: showRed(),
        chaindId: chaindId(),
        logo: logoComputed(),
        name: getConnectorName(),
      };
    });

    const deposit = () => {
      storage.cubistNewer.value = "false";
      const { provider } = useProvider();
      if (provider.value instanceof CubistProvider) {
        const cubistProvider = provider.value;
        cubistProvider.clearNewFlagToCubist();
      }
    };

    const disconnect = async () => {
      try {
        await connectors.value[activeConnector.value]?.disconnect();
      } catch (error) {
        console.log(error);
      }
      storage.isDisConnect.value = true;
      setProvider(undefined);
      ethereumStore.reset();
    };

    const switchPopoerShow = ref(false);

    function handVersionChange(v) {
      const version = v.detail[0];
      console.log(version);
    }
    return {
      switchPopoerShow,
      disconnect,
      handVersionChange,
      dataRef,
      deposit,
    };
  },
  computed: {
    ...mapState(EthereumStore, ["accountConnected", "account", "network"]),
    ...mapState(LocalStorage, ["cubistNewer", "lastConnector"]),
  },
});
</script>
<style lang="scss">
.virtual-wallet-content {
  border-color: #40444f;

  .virtual-wallet-list {
    border-color: #40444f;
    height: 48px;
    align-content: center;
  }

  .virtual-wallet-list:hover {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 15px 15px 15px 15px;
    height: 48px;
    align-content: center;
  }

  .text-label {
    color: #6c86ad;
  }

  .text {
    color: #c7cad9;
  }
}

.loading-spinner {
  svg {
    animation: loading-rotate 1s linear infinite;
  }

  @keyframes loading-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
