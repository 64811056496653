import { gurujiDomain } from "@/constants";
import axios from "axios";
import { useWallet } from "@/dappos/hooks/useWallet";
import TriggerEvent from "@/dappos/utils/triggerEvent";
import { useServiceCaller } from "@/dappos/hooks/useServiceCaller";
import SuccessSvg from "@/assets/img/svg/success.svg";
import ErrorSvg from "@/assets/img/svg/error.svg";
import { Notification } from "@arco-design/web-vue";
import { h } from "vue";
import { useNeedConnectWalletDialog } from "@/dappos/hooks/useNeedConnectWalletDialog";
import { useClaimSuccessDialog } from "@/dappos/hooks/useClaimSuccessDialog";

export const useActionCaller = () => {
  const { isReady, vwAddress, eoaAddress } = useWallet();
  const { startTransactionProcess } = useServiceCaller();
  const { isConnected, openNeedConnectWalletDialog } =
    useNeedConnectWalletDialog();

  const { open: openClaimSuccessDialog } = useClaimSuccessDialog();

  const callClaimRewards = async (gems, task) => {
    if (!isConnected.value) {
      openNeedConnectWalletDialog();
      return;
    }
    if (!(isReady.value && vwAddress.value)) {
      return;
    }

    const vw = vwAddress.value;
    const eoa = eoaAddress.value;

    // get signature from api
    const getSignature = async () => {
      const url = `${gurujiDomain}/solar/dappos/task_claim_signature`;
      const res = await axios.get(url, {
        params: { gems, vwAddress: eoa, task }, // ! because new user's vw is 0x0...000 after register, use eoa to get signature
      });
      return res.data?.data?.record_data ?? {};
    };

    const {
      signature: validatorSignature,
      nonce,
      reward,
    } = await getSignature();

    // call contract
    const res = await startTransactionProcess(
      "claimRewards",
      {
        reward,
        nonce,
        validatorSignature,
      },
      false,
      "Claim Rewards"
    );

    if (res?.isSuccess) {
      // call callback api
      const signCallback = async () => {
        const url = `${gurujiDomain}/solar/dappos/task_callback`;
        const res = await axios.get(url, {
          params: { gems, vwAddress: vw, eoaAddress: eoa, task },
        });
        return res.data.data;
      };
      const res = await signCallback();
      if (!res.code) {
        Notification.success({
          title: `Claim Rewards Success`,
          content: null,
          duration: 1000 * 10.33,
          closable: true,
          showIcon: true,
          icon: h("img", { src: SuccessSvg }),
          class: "dappos_notify",
        });
        openClaimSuccessDialog(gems);
      } else {
        Notification.error({
          title: `Claim Rewards failure`,
          content: null,
          duration: 1000 * 10.33,
          closable: true,
          showIcon: true,
          icon: h("img", { src: ErrorSvg }),
          class: "dappos_notify",
        });
      }
      TriggerEvent.emit("loadHomeInfo");
    }
  };
  return {
    callClaimRewards,
  };
};
