<template>
  <template v-if="accountConnected && transactionState.isPolling">
    <div
      class="transactionState"
      style="color: white; border-radius: 10em"
      @click="goDetail"
    >
      Pending
      <span class="count">{{ transactionState.pendingCount }}</span>
      <div class="processWrapper">
        <span class="processBg"></span>
        <span
          class="process"
          :style="{ width: `${transactionState.process}%` }"
        ></span>
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed } from "vue";
import { useTransactionsStore } from "@/stores/transactions.store";
import { useWallet } from "@/dappos/hooks/useWallet";
import { AddressZero } from "@/constants";

const { eoaAddress } = useWallet();
const { transactionsState: state } = useTransactionsStore();
const transactionState = computed(() => state.transactionState.value);
const accountConnected = computed(() => {
  return eoaAddress.value == AddressZero ? false : true;
});

const goDetail = () => {
  if (!state.transactionState.value.pendingCount) return;
  const hash = state.transactionState.value.transactions[0].hash;
  window.open(
    `${process.env.VUE_APP_DAPPOS_EXPLORER_HOST}/activities/details?order_hash=${hash}`
  );
};
</script>

<style scoped lang="scss">
.transactionState {
  margin-left: 8px;
  position: relative;
  padding: 0 10px 10px 2.6rem;
  cursor: pointer;
  border-radius: 9999px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  background: rgb(4 195 142);
  &:hover {
    background: #8bdcbd;
  }
  &::before {
    position: absolute;
    top: 0.76rem;
    left: 1rem;
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    background: url("@/assets/img/svg/process.svg") no-repeat center center/100%
      100%;
    animation: process 1s infinite;
  }
  .count {
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
    display: block;
    width: 1.14rem;
    height: 1.14rem;
    line-height: 1.14rem;
    background: #fa5151;
    border-radius: 1.14rem;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
  .processWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 80%;
    height: 100%;
    background: transparent;
    border-radius: 0.375rem;
    overflow: hidden;
    .processBg {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 2px;
      background: rgba(255, 255, 255, 0.2);
    }
    .process {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 50%;
      height: 2px;
      background: #ffffff;
      transition: width 0.5s;
    }
  }
}
@keyframes process {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
