<template>
  <a-modal
    :visible="isDisplay"
    @cancel="closeDialog"
    modal-class="task-center-wallet-connector"
    :mask-closable="false"
    :render-to-body="true"
    :footer="false"
    width="343px"
    :title-align="'center'"
    unmount-on-close
  >
    <template #title> Choose Network </template>
    <div>
      <a-space direction="vertical" :size="[0, 10]" fill>
        <div
          :key="index"
          v-for="(item, index) in networksList"
          class="task-center-wallet-connector-cell"
          @click="switchChain(item.chainId)"
        >
          <div class="task-center-modal-icon">
            <img class="icon" :src="item.icon" />
          </div>
          <span> {{ item.chainName }}</span>
        </div>
      </a-space>
    </div>
  </a-modal>
</template>
<script setup>
import { watch } from "vue";
import { useNetworksDialog } from "@/dappos/hooks/useNetworksDialog";
import { supportChains } from "@/constants";
import { getEvmChainParameter } from "@/constants";
import { switchChain } from "@/modules/connector/methods";
import { useWallet } from "../hooks/useWallet";
const { close, isOpen: isDisplay } = useNetworksDialog();
const { srcChainId } = useWallet();

const networksList = supportChains.map((chainId) => {
  const networkInfo = getEvmChainParameter(chainId);
  return {
    ...networkInfo,
    icon: networkInfo.iconUrls[0],
  };
});

// force to polygon chain
const fixedChainId = 137;
watch(srcChainId, () => {
  switchToFixedChainId();
});

const switchToFixedChainId = () => {
  if (srcChainId.value !== fixedChainId) {
    switchChain(fixedChainId);
  }
};

watch(srcChainId, () => {
  close();
});

function closeDialog() {
  close();
}
</script>
<style lang="scss" scoped>
.task-center-wallet-connector-cell {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  span {
    margin-left: 10px;
    color: black;
    font-size: 14px;
  }
  &:hover {
    background-color: rgb(45 45 45 / 10%);
  }
}
.task-center-modal-icon {
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 50%;
  padding: 1px;
  background-color: white;
  width: 24px;
  height: 24px;
  .icon {
    border-radius: 50%;
    width: 22px;
  }
}
</style>
