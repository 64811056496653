import { ref } from "vue";

const isOpen = ref(false);

const gems = ref(0);

export function useClaimSuccessDialog() {
  const open = ({ gems: g }) => {
    if (g) gems.value = g;

    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  return {
    gems,
    isOpen,
    open,
    close,
  };
}
