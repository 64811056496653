import { computed, markRaw, ref } from "vue";
import { useProvider } from "@/composables/use-provider";
import { MetaMaskConnector, ethereum } from "@/modules/connector";
// import { WalletConnectConnector } from "@/modules/connector/wallet-connect";
import { useEthereumStore } from "@/stores/ethereum.store";
import { useConnectedNetwork } from "@/stores/hooks/use-network";
import { useStorageStore } from "@/stores/storage.store";
import { OIDCConnectConnector } from "@/modules/connector/oidc-connect";
import { useRoute } from "vue-router";
import { useWalletConnectorDialog } from "@/dappos/hooks/useWalletConnectorDialog";
const metamask = ethereum ? new MetaMaskConnector() : undefined;
// const walletconnect = new WalletConnectConnector();
const activeConnector = ref();
const oidcconnect = new OIDCConnectConnector({ autoConnect: true });

export const useConnector = () => {
  const { storage } = useStorageStore();
  const { open } = useWalletConnectorDialog();

  const connectors = computed(() => {
    return {
      [IConnector.METAMASK]: metamask && markRaw(metamask),
      // [IConnector.WALLET_CONNECT]: markRaw(walletconnect),
      [IConnector.GOOGLE]: markRaw(oidcconnect),
      [IConnector.TWITTER]: markRaw(oidcconnect),
    };
  });

  const autoConnect = async () => {
    const router = useRoute();
    console.log("route", router);
    if (!storage.isDisConnect.value) {
      try {
        const result = await connectors.value[
          storage.lastConnector.value
        ]?.isConnected();
        if (result) {
          await connect(storage.lastConnector.value, false);
        } else {
          if (
            storage.connectorFrom.value &&
            storage.connectorFrom.value === "cubist" &&
            storage.referralCode_v2.value
          ) {
            open();
          }
        }
      } catch (error) {
        if (
          storage.connectorFrom.value &&
          storage.connectorFrom.value === "cubist" &&
          storage.referralCode_v2.value
        ) {
          open();
        }
      }
    } else if (
      storage.connectorFrom.value &&
      storage.connectorFrom.value === "cubist" &&
      storage.referralCode_v2.value
    ) {
      open();
    }
  };

  return {
    activeConnector,
    connect,
    autoConnect,
    connectors,
  };
};

const setAccount = async (opt) => {
  const { accounts, provider, network: chainId } = opt;
  const { setProvider } = useProvider();
  const { ethereumStore, ethereumState } = useEthereumStore();
  const networkStore = useConnectedNetwork();

  if (accounts[0]) {
    setProvider(provider);
    ethereumState.account.value = accounts[0];
    networkStore.setNetwork(chainId);
    ethereumStore.event.emit("connected", {
      account: accounts[0],
      chainId: Number(chainId),
    });
    ethereumStore.event.emit("connectChanged", {
      account: accounts[0],
      network: ethereumState.network.value,
    });
  }
};

export const connect = async (type, reset) => {
  const { connectors } = useConnector();
  const { setProvider } = useProvider();
  const { storage } = useStorageStore();
  const { ethereumStore, ethereumState } = useEthereumStore();
  const networkStore = useConnectedNetwork();
  const connector = connectors.value[type];
  console.log("[ abc ] type reset", type, reset, connector);
  await connector
    ?.connect(reset)
    .then((e) => {
      activeConnector.value = type;
      try {
        // It seems useless
        // const { getInviteCode, bindInvite } = useStorageStore();
        // getInviteCode(e.accounts[0]);
        // if (storage.referralCode_v2) {
        //   bindInvite(storage.referralCode_v2.value, e.accounts[0]);
        // }
      } catch (e) {
        console.log(e);
      }
      return setAccount(e);
    })
    .then(() => {
      storage.isDisConnect.value = false;
      storage.lastConnector.value = type;
      connector?.onAccountsChanged((accounts) => {
        if (accounts[0]) {
          ethereumState.account.value = accounts[0];
          storage.walletVersion.value = null;
        } else {
          ethereumStore.reset();
        }
        ethereumStore.event.emit("connectChanged", {
          account: accounts[0],
          network: ethereumState.network.value,
        });
      });
      connector?.onChainChanged((chainId) => {
        setProvider(connector.provider);
        networkStore.setNetwork(Number(chainId));
        ethereumStore.event.emit("connectChanged", {
          account: ethereumState.account.value,
          network: ethereumState.network.value,
        });
      });
      return;
    });
};

export const IConnector = {
  WALLET_CONNECT: "WalletConnect",
  METAMASK: "MetaMask",
  GOOGLE: "google",
  TWITTER: "twitter",
  FACEBOOK: "facebook",
};
