// import { computed, h } from "vue";
import { computed } from "vue";
import { useTellerXService } from "@/dappos/service";
import { useTransactionsStore } from "@/stores/transactions.store";
// import { Notification } from "@arco-design/web-vue";
// import SuccessSvg from "@/assets/img/svg/success.svg";
// import ErrorSvg from "@/assets/img/svg/error.svg";
// import CloseSvg from "@/assets/img/svg/close.svg";

export const useServiceCaller = () => {
  const { transactionsStore } = useTransactionsStore();
  const { loading, signIn, claimRewards } = useTellerXService();

  const { pollOrderHash } = transactionsStore;

  const startTransactionProcess = async (key, args, isSimulated, title) => {
    const hashOrResult = await serviceFn(key)(args, isSimulated).catch(
      (error) => {
        console.error("[" + title + "] error", error);
        if (error.message) {
          //   Notification.info({
          //     title: title,
          //     content: error.message,
          //     closable: true,
          //     showIcon: true,
          //     duration: 1000 * 5,
          //     icon: h("img", { src: ErrorSvg }),
          //     closeIcon: h("img", { src: CloseSvg }),
          //   });
        }
      }
    );

    if (isSimulated) return hashOrResult; // for simulating in dappos

    if (!hashOrResult && !isSimulated) return; // for rejected in dappos without hash

    // after sending transaction successfully below
    const hash = hashOrResult;

    // ! start pending here
    const pollOrderRes = await pollOrderHash(hash);
    if (pollOrderRes?.data.orderHash === hash) {
      if (pollOrderRes?.isSuccess) {
        // TODO reload balance info data
        return {
          isSuccess: true,
          orderHash: hash,
        };
      } else {
        return {
          isSuccess: false,
          orderHash: hash,
        };
      }
    }
  };

  const serviceFn = (key) => {
    console.log("serviceFnkey: ", key);
    const hashMap = {
      signIn,
      claimRewards,
    };
    return hashMap[key];
  };

  return {
    startTransactionProcess,
    loading: computed(() => loading.value),
  };
};
