export const isPC = () => {
  const userAgentInfo = navigator.userAgent;
  const agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let flag = true;

  for (let i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > -1) {
      flag = false;
      break;
    }
  }

  return flag;
};

export function numberWithCommas(x) {
  if (!x) {
    return "...";
  }

  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const limitDecimals = (amount, maxDecimals) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(
        0,
        amountStr.length - (decimals - maxDecimals)
      );
    }
  }
  return amountStr;
};

export const formatNumberUsingCash = (n) => {
  console.log("n", n);
  n = Number(n);
  if (n < 1e3) return n.toString();
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "k";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "m";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "b";
  if (n >= 1e12) return +(n / 1e12).toFixed(2) + "t";
};
