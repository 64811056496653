import { shallowRef } from "vue";
import { getEvmChainParameter } from "@/constants";
import { getRetryProvider } from "@dappos/v2-sdk";
/**
 * @private
 */
const provider = shallowRef();

export const useProvider = () => {
  const setProvider = (_provider) => {
    provider.value = _provider;
  };

  return {
    provider,
    setProvider,
  };
};

/**
 * getProvider
 * @description get connected or custom provider
 * @returns Provder
 */

const provider_cache = new Map();
export function getProvider(chainId) {
  const { provider } = useProvider();
  if (!chainId) {
    return provider.value;
  }
  const EvmChainParameter = getEvmChainParameter(chainId);
  if (!EvmChainParameter) {
    throw Error(` Not found this chainId: ${chainId}`);
  } else {
    if (provider_cache.has(Number(EvmChainParameter.chainId))) {
      return provider_cache.get(Number(EvmChainParameter.chainId));
    } else {
      const { rpcUrls } = EvmChainParameter;

      const network = { chainId: Number(chainId), name: "unknown" };
      const rpcUrlSort = (rpcUrls ?? []).sort((a, b) => {
        try {
          if (window?.location?.host?.includes("dappos.")) return 0;
        } catch (error) {
          console.error(error);
        }
        return (
          Number(/ankr\.com|infura/.test(a)) -
          Number(/ankr\.com|infura/.test(b))
        );
      });

      console.debug("rpcUrlSort: ", rpcUrlSort);
      const _provider = getRetryProvider(rpcUrlSort, {
        network: network,
      });
      provider_cache.set(Number(chainId), _provider);
      return _provider;
    }
  }
}
