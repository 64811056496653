<template>
  <!-- hide this button -->
  <div class="network-button" v-if="false">
    <div v-if="!isNeedSwitchChain" class="network-icon" @click="open()">
      <img :src="icon ?? getEvmChainParameter(137).iconUrls[0]" />
    </div>
    <div v-else class="network-text" @click="open()">
      {{ text }}
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useEthereumStore } from "@/stores/ethereum.store";
import { supportChains } from "@/constants";
import { getEvmChainParameter } from "@/constants";
import { useNetworksDialog } from "@/dappos/hooks/useNetworksDialog";
import { useWalletConnectorDialog } from "../hooks/useWalletConnectorDialog";

const { ethereumState } = useEthereumStore();

const { network, accountConnected } = ethereumState;

const { open: openNetworksDialog } = useNetworksDialog();

const { open: openWalletConnectorDialog } = useWalletConnectorDialog();

const open = () => {
  if (!accountConnected.value) {
    openWalletConnectorDialog();
  } else {
    openNetworksDialog();
  }
};

const isNeedSwitchChain = computed(() => {
  if (!network.value) return false;
  return !supportChains.includes(Number(network.value.chainId));
});

const text = computed(() => {
  if (isNeedSwitchChain.value) {
    return "Switch Network";
  }
  return "";
});

const icon = computed(() => {
  if (!accountConnected.value) {
    return;
  } else {
    return getEvmChainParameter(network.value.chainId).iconUrls[0];
  }
});
</script>
<style lang="scss" scoped>
.network-button {
  cursor: pointer;
  min-width: 43px;
  height: 43px;
  border-radius: 14px;
  background-color: white;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  .network-icon {
    border-radius: 43px;
    border: 1px solid black;
    background-color: black;
    width: 32px;
    height: 32px;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .network-text {
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    color: #000;
  }
}
</style>
