import { ref } from "vue";

const isOpen = ref(false);
const currentOrder = ref();
export function useTxDialog() {
  const open = (o) => {
    isOpen.value = true;
    currentOrder.value = o;
  };

  const close = () => {
    isOpen.value = false;
    currentOrder.value = undefined;
  };

  return {
    currentOrder,
    isOpen,
    open,
    close,
  };
}
