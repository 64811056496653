<template>
  <div class="app-container">
    <!-- below for Google cubist -->
    <div id="my-signin2" style="display: none"></div>
    <AppHeader />
    <div class="app-router-view">
      <router-view />
    </div>
  </div>
  <OrderDialog />
  <WalletConnectorDialog />
  <NetworksDialog />
  <SignInSuccessDialog />
  <ClaimSuccessDialog />
  <NeedConnectWalletDialog />
</template>
<script setup>
import AppHeader from "@/components/AppHeader/AppHeader";
import OrderDialog from "@/dappos/components/orderDialog";
import NetworksDialog from "@/dappos/components/networksDialog";
import WalletConnectorDialog from "@/dappos/components/walletConnector";
import SignInSuccessDialog from "@/dappos/components/signInSuccessDialog";
import ClaimSuccessDialog from "@/dappos/components/claimSuccessDialog";
import NeedConnectWalletDialog from "@/dappos/components/needConnectWalletDialog";
import { GoogleController, CubistProvider } from "cubist-provider";
import { onMounted } from "vue";

// below for Twitter cubist
CubistProvider.twitterAuthCallBack();
// below for Google cubist
onMounted(() => {
  GoogleController.initSdk(() => {
    GoogleController.renderGoogleButtonOnPj(document);
  });
});
</script>

<style lang="scss">
.app-container {
  height: 100%;
}
</style>
