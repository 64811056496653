import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/stores/index";
import "@/theme/base.scss";
import "@dappos/checkout/style.css";
import { Message } from "@arco-design/web-vue";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import ArcoVue from "@arco-design/web-vue";
import "@arco-design/web-vue/dist/arco.css";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(ArcoVueIcon);
app.use(ArcoVue);
Message._context = app._context;
app.config.globalProperties.$message = Message;

app.mount("#app");
